'use client'
import { FormControl, IconButton, InputAdornment, TextField } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './index.module.scss'
import { flatMap, isEqual } from 'lodash-es'
import JobItem from './item'
import Header from './header'
import classNames from 'classnames'
import MaterialButton from 'components/MaterialButton'
import Text from 'components/Text'
import { useRef } from 'react'
import { debounce } from 'lodash-es'
import { isMobile } from 'react-device-detect'

const toSeo = (value) => {
  if (!value) return
  return value.replaceAll('/', '-')?.replaceAll(' ', '-')?.toLowerCase()
}
const preventEventBroadcast = (event) => {
  event.stopPropagation()
  event.preventDefault()
}

const DownArrow = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='11' height='7' viewBox='0 0 11 7' fill='none'>
    <path
      d='M9.46298 0.966675C10.3706 0.966675 10.8088 2.07844 10.1453 2.69773L6.18232 6.39651C5.79811 6.7551 5.20189 6.7551 4.81768 6.39651L0.854703 2.69773C0.191182 2.07844 0.629399 0.966674 1.53702 0.966674L9.46298 0.966675Z'
      fill='#86909C'
    />
  </svg>
)

const JobFunctionMultiSelector = (props: any) => {
  const {
    label,
    className,
    value,
    isTouched,
    onChange,
    lang,
    salaryTypeLists,
    Icon,
    initHeight,
    ...rest
  } = props

  const [showModal, setShowModal] = useState(false)
  const [firstRender, setFirstRender] = useState(true)
  const [isClosing, setIsClosing] = useState(false)
  const [activeFirst, setActiveFirst] = useState<any>()
  const [activeSecond, setActiveSecond] = useState<any>()
  const secondList = useMemo(() => {
    return activeFirst?.children ?? []
  }, [activeFirst])
  const [salaryType, setMainfunctions] = useState(value?.salaryType ?? [])

  const [salaryIds, setFunctionIds] = useState(value?.salary ?? [])
  // const [functionTitleIds, setFunctionTitleIds] = useState(value?.functionTitles ?? [])
  const [height, setHeight] = useState(0)
  useEffect(() => {
    if (!isMobile) {
      document.body.style.overflow = 'auto'
      return
    }
    if (showModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
      setActiveFirst(null)
      setActiveSecond(null)
    }

    // return () => document.body.style.overflow = 'auto'
  }, [showModal, isMobile])
  useEffect(() => {
    const convertStyle = debounce(() => {
      setHeight(window.innerHeight)
    }, 50)
    setHeight(window.innerHeight)
    window.addEventListener('resize', convertStyle)
    window.addEventListener('DOMContentLoaded', convertStyle)
    return () => {
      window.removeEventListener('resize', convertStyle)
      window.removeEventListener('DOMContentLoaded', convertStyle)
    }
  }, [])

  useEffect(() => {
    if (
      !isEqual(value, {
        salaryType: salaryType,
        salary: salaryIds
        //   functionTitles: functionTitleIds
      })
    ) {
      setMainfunctions(value?.salaryType ?? [])
      setFunctionIds(value?.salary ?? [])
      //  setFunctionTitleIds(value?.functionTitles ?? [])
    }
  }, [value])
  const preShowModal = useRef(false)
  useEffect(() => {
    if (!showModal && preShowModal.current && !isMobile) {
      onChange?.({
        salaryType: salaryType,
        salary: salaryIds
        //    functionTitles: functionTitleIds
      })
    }
    preShowModal.current = showModal
  }, [showModal])
  const formattedJobfunctions = useMemo(() => {
    return salaryTypeLists
      .filter((v) => Boolean(v.children))
      .map((obj, index) => {
        const firstParent = {
          value: obj.value,
          id: index,
          seo_value: obj.seo_value,
          key: obj.key,
          children: undefined
        }
        firstParent.children = (obj?.children ?? []).map((second) => {
          const secondParent = {
            id: second.id,
            value: second.value,
            seo_value: toSeo(second['seo_value'] || second['seo-value']),
            key: toSeo(second['seo_value'] || second['seo-value']),
            parent: firstParent,
            children: undefined
          }
          return secondParent
        })
        return firstParent
      })
  }, [])

  const allSeconds = useMemo(
    () => flatMap(formattedJobfunctions, (item) => item.children),
    [formattedJobfunctions]
  )

  const textValue = useMemo(() => {
    return (
      salaryIds.map((key) => allSeconds.find((item) => item.seo_value === key)?.value).join(',') ??
      ''
    )
  }, [salaryType, salaryIds, allSeconds, formattedJobfunctions])

  const isSecondSelected = useCallback(
    (second) => {
      return salaryIds.includes(second.seo_value)
    },
    [salaryIds, salaryType]
  )

  const isFirstSelected = useCallback(
    (first) => {
      return salaryType.includes(first.seo_value)
    },
    [salaryType, isSecondSelected]
  )

  const onFirstClick = useCallback(
    (first) => {
      if (salaryType.includes(first.seo_value)) {
        setMainfunctions(salaryType.filter((key) => key !== first.seo_value))
        setFunctionIds([])
      } else {
        setMainfunctions([first.seo_value])
        const selectedSecondIds = first.children.map((second) => second.seo_value)
        setFunctionIds(selectedSecondIds)
      }
    },
    [salaryType, salaryIds]
  )

  const onSecondClick = useCallback(
    (second) => {
      if (second.id === -1) {
        onFirstClick(second.parent)
      } else {
        setMainfunctions([second.parent.seo_value])
        if (salaryIds.includes(second.seo_value)) {
          const newSalaryIds = salaryIds.filter((key) => key !== second.seo_value)
          if (newSalaryIds.length === 0) {
            setMainfunctions([])
          }
          setFunctionIds(newSalaryIds)
        } else {
          const newSelected = [
            second.seo_value,
            ...salaryIds.filter((key) =>
              second.parent.children.map((item) => item.seo_value).includes(key)
            )
          ]
          setFunctionIds(newSelected)
        }
      }
    },
    [onFirstClick, salaryIds, salaryType]
  )
  const listener = useCallback((e) => {
    if (e.target.id?.includes?.('job-item')) {
      return
    }
    setShowModal(false)
    document.removeEventListener('click', listener)
  }, [])
  useEffect(() => {
    if (!isMobile) {
      if (showModal) {
        setTimeout(() => {
          document.addEventListener('click', listener)
        }, 1000)
      } else {
        document.removeEventListener('click', listener)
      }
      return () => document.removeEventListener('click', listener)
    }
  }, [showModal])
  const onBack = useCallback(() => {
    setActiveFirst(undefined)
    setActiveSecond(undefined)
  }, [])
  const clear = useCallback(() => {
    setMainfunctions([])
    setFunctionIds([])
  }, [value])
  const animationClose = useCallback(() => {
    setIsClosing(true)
    setTimeout(() => {
      setShowModal(false)
      setIsClosing(false)
      setFirstRender(true)
    }, 300)
  }, [])
  const onClose = useCallback(() => {
    clear()
    animationClose()
  }, [clear, animationClose])

  const onSave = useCallback(() => {
    onChange?.({
      salaryType: salaryType,
      salary: salaryIds
    })
    animationClose()
  }, [onChange, salaryType, salaryIds, animationClose])

  const onSecondHover = useCallback((hoverThirdList) => {
    setActiveSecond(hoverThirdList)
  }, [])
  const onFirstHover = useCallback(
    (hoverSecondList) => {
      if (firstRender) {
        setFirstRender(false)
      }
      if (activeFirst?.seo_value !== hoverSecondList.seo_value) {
        setActiveFirst(hoverSecondList)
        hoverSecondList
        setActiveSecond(undefined)
      }
    },
    [activeFirst]
  )
  const valueText = useMemo(() => {
    const selected = textValue.split(',').filter((a) => a)
    return `${label} ${selected?.length ? `(${selected.length})` : ''}`
  }, [textValue, label])
  return (
    <FormControl className={className} size='small'>
      {showModal && <div className={styles.mask} onClick={listener}></div>}
      <TextField
        value={valueText}
        autoComplete='off'
        label={<div style={{ position: 'relative', bottom: 7 }}>{label}</div>}
        onClick={(e) => {
          preventEventBroadcast(e)
          setShowModal(true)
        }}
        classes={{}}
        style={{
          background: textValue.split(',').filter((a) => a)?.length ? '#E7F1FB' : '#F0F0F0'
        }}
        className={styles.initSalary}
        inputProps={{
          readOnly: true,
          style: {
            color: textValue.split(',').filter((a) => a)?.length ? '#136FD3' : 'rgba(0, 0, 0, 0.6)',
            background: textValue.split(',').filter((a) => a)?.length ? '#E7F1FB' : '#F0F0F0'
          }
        }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton>{Icon ? Icon : <DownArrow />}</IconButton>
            </InputAdornment>
          )
        }}
        disabled={showModal}
        onFocus={(e) => {
          preventEventBroadcast(e)
          if (!isTouched) {
            setShowModal(true)
          }

          rest?.onFocus?.(e)
          Promise.resolve().then(() => {
            e.target.blur()
          })
        }}
        {...rest}
      />
      {showModal && (
        <div
          className={styles.container}
          onClick={preventEventBroadcast}
          onScroll={preventEventBroadcast}
        >
          <div className={styles.column} onScroll={preventEventBroadcast}>
            {formattedJobfunctions.map((first) => {
              return (
                <JobItem
                  key={first.value}
                  data={first}
                  active={activeFirst?.seo_value === first.seo_value}
                  checked={isFirstSelected(first)}
                  onMouseOver={() => onFirstHover(first)}
                  onClick={() => onFirstClick(first)}
                />
              )
            })}
          </div>
          {secondList.length > 1 && (
            <div className={styles.column} onScroll={preventEventBroadcast}>
              {secondList.map((second: any) => {
                return (
                  <JobItem
                    key={second.id}
                    data={second}
                    active={salaryIds?.includes(second.seo_value)}
                    checked={isSecondSelected(second)}
                    onMouseOver={() => onSecondHover(second)}
                    onClick={() => {
                      onSecondClick(second)
                    }}
                    noArrow
                  />
                )
              })}
            </div>
          )}
        </div>
      )}
      {showModal && isMobile && (
        <div
          className={classNames({
            [styles.mobile]: true,
            ['isMobile']: true,
            [styles.showModal]: firstRender,
            [styles.closingModal]: isClosing
          })}
          onClick={preventEventBroadcast}
          style={{ height: initHeight ? initHeight : height - 50 }}
        >
          <div className={styles.topContainer}>
            <div
              className={classNames({
                [styles.firstPage]: true,
                [styles.show]: !!activeFirst && !firstRender,
                [styles.hide]: !activeFirst && !firstRender
              })}
            >
              <Header title={label} onClose={animationClose}></Header>

              <div
                className={styles.columnMain}
                style={{ height: initHeight ? initHeight : height - 200 }}
              >
                {formattedJobfunctions.map((first) => {
                  return (
                    <JobItem
                      key={first.value}
                      data={first}
                      active={activeFirst?.seo_value === first.seo_value}
                      checked={isFirstSelected(first)}
                      onArrowClick={(e) => {
                        e.stopPropagation()
                        onFirstHover(first)
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                        onFirstClick(first)
                      }}
                    />
                  )
                })}
              </div>
            </div>
            <div
              className={classNames({
                [styles.secondPage]: true,
                [styles.show]: !!activeFirst && !firstRender,
                [styles.hide]: !activeFirst && !firstRender
              })}
            >
              <Header title={activeFirst?.value} onBack={onBack} onClose={onClose}></Header>
              <div
                className={styles.subContainer}
                style={{ height: initHeight ? initHeight : height - 200 }}
              >
                <div className={styles.secondContainer}>
                  {secondList.length > 1 && (
                    <div className={styles.columnSub}>
                      {secondList.map((second: any) => {
                        return (
                          <JobItem
                            key={second.id}
                            data={second}
                            active={activeSecond?.seo_value === second.seo_value}
                            checked={isSecondSelected(second)}
                            onArrowClick={(e) => {
                              e.stopPropagation()
                              onSecondHover(second)
                            }}
                            onClick={() => {
                              onSecondHover(second)
                              onSecondClick(second)
                            }}
                            noArrow
                          />
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <MaterialButton
              variant='outlined'
              capitalize
              onClick={clear}
              sx={{ height: '44px', width: '40%', borderRadius: '4px' }}
            >
              <Text textColor='primaryBlue' bold>
                {lang?.reset2}
              </Text>
            </MaterialButton>

            <MaterialButton
              variant='contained'
              capitalize
              onClick={onSave}
              sx={{ height: '44px', width: '40%', borderRadius: '4px' }}
            >
              <Text textColor='white' bold>
                {lang?.save}
              </Text>
            </MaterialButton>
          </div>
        </div>
      )}
    </FormControl>
  )
}

export default JobFunctionMultiSelector
