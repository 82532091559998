'use client'
import React from 'react'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import NotificationProvider from 'components/NotificationProvider'
import { store } from '../../models/store'
import MaintenancePage from 'app/maintenance/page'
import LocationProvider from './locationProvier'
import LoadingProvider from './loadingProvider'
import LanguageProvider from './languageProvider'
import { ProfileProvider } from './profileProvider'
import ContextComposer from './ContextComposer'
import ConfigProvider from './configProvider'
// import IMProvider from 'app/(remote-chat)/[lang]/chat/[chat_id]/components/IMProvider.client'
import dynamic from 'next/dynamic'
import { getCookie } from 'helpers/cookies'

const VIf = (props) => (props.show ? <>{props.children}</> : null)
const IMProvider = dynamic(
  () => import('app/(remote-chat)/[lang]/chat/[chat_id]/components/IMProvider.client'),
  { ssr: false }
)
interface ClientProvidersProps {
  children: React.ReactNode
  lang: any
}

const ClientProviders = (props: ClientProvidersProps) => {
  const { children }: React.PropsWithChildren = props
  const { lang }: any = props
  const isLogin = !!getCookie('sessionid')
  return (
    <Provider store={store} key={'MaintainerProvider'}>
      <VIf show={process.env.MAINTENANCE === 'true'}>
        <MaintenancePage />
      </VIf>

      <VIf show={process.env.MAINTENANCE !== 'true'}>
        <ContextComposer
          contexts={[
            <ConfigProvider key='ConfigProvider' />,
            <CookiesProvider key={'CookiesProvider'} />,
            <LocationProvider key={'LocationProvider'} />,
            <LanguageProvider lang={lang} key={'LanguageProvider'} />,
            isLogin && <ProfileProvider key={'ProfileProvider'} />,
            <NotificationProvider key={'NotificationProvider'} />,
            <LoadingProvider lang={lang} key={'LoadingProvider'} />,
            isLogin && <IMProvider />
          ].filter((a) => a)}
        >
          {children}
        </ContextComposer>
      </VIf>
    </Provider>
  )
}

export default ClientProviders
