import React, { useState, useRef, useLayoutEffect } from 'react'

/* Vendors */
import classNames from 'classnames/bind'

/* Assets */
import styles from './Accordian.module.scss'

/* Constant */
import { Plus, Minus, ChevronUpIcon, ChevronDownIcon } from 'images'

const cx = classNames.bind(styles)

interface AccordianProps {
  title?: string | React.ReactNode
  children: React.ReactNode
  className?: string
  style?: object
  paddedLine?: boolean
  paddedContent?: boolean
  scrollableContent?: boolean
  chevronIcon?: boolean
  defaultOpenState?: boolean
  isNotCollapsible?: boolean
  dark?: boolean
}

const Accordian = ({
  title,
  children,
  className,
  style,
  paddedLine,
  paddedContent,
  scrollableContent,
  chevronIcon,
  defaultOpenState = false,
  isNotCollapsible = false,
  dark,
  ...rest
}: AccordianProps) => {
  const [isVisible, setIsVisible] = useState(defaultOpenState)
  const [contentHeight, setContentHeight] = useState(0);

  const handleClick = () => {
    if (!isNotCollapsible) setIsVisible(!isVisible)
  }

  const componentClass = {
    accordian: true,
  }
  const titleClass = {
    accordianPaddedLine: paddedLine,
    accordianTitle: true,
  }
  const contentClass = {
    accordianContent: paddedContent,
    accordianContentScroll: scrollableContent,
  }
  const accordianClass = cx([componentClass, className])
  const contentClasses = cx([contentClass])
  const titleClasses = cx([titleClass])

  const contentRef = useRef(null);


  const calculateContentHeight = () => {
    setContentHeight(contentRef.current?.scrollHeight ?? 0 + 16);
  };
  useLayoutEffect(() => {
    calculateContentHeight();
  });
  return (
    <div className={accordianClass} style={style} {...rest}>
      <div className={titleClasses} onClick={handleClick}>
        {title}
        <div className={styles.accordianToggleIcon}>
          {isVisible ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
              <path d="M1.5 9.46924L6 4.96924L10.5 9.46924" stroke="#B8B8B8" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
              <path d="M10.5 4.46924L6 8.96924L1.5 4.46924" stroke="#B8B8B8" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          )}
        </div>
      </div>
      <div className={contentClasses} style={{ overflow: "hidden", height: isVisible ? contentHeight + 'px' : '0px' }}>
        <div
          className={`${contentClasses} ${isVisible ? styles.accordianVisible : styles.accordianHidden
            }`}

          ref={contentRef}
        >
          {children}
        </div>
      </div>

    </div>
  )
}

export default Accordian
