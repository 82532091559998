import styles from './index.module.scss'
import classNames from 'classnames'
const JobItem = (props: any) => {
  const { data, checked, active, noArrow, onArrowClick, halfChecked, inputValue, ...rest } = props
  return (
    <div
      className={classNames({
        [styles.item]: true,
        [styles.active]: active
      })}
      id={'job-item-' + data.value + data?.id}
      {...rest}
    >
      {/* <div className={styles.icon}>
        {(checked || halfChecked) && (
          <CheckCircleIcon
            style={{ opacity: checked ? 1 : 0.5 }}
            id={'job-item-icon' + data.value + data?.id}
            fill='red'
            height='16'
            width='16'
          />
        )}
      </div> */}

      <div
        id={'job-item-label' + data.value + data?.id}
        contentEditable={false}
        style={{ flex: 1 }}
        className={`${styles.content} ${(checked || halfChecked) && styles.contentActive} `}
      >
        <span
          className={`${styles.iconBox} ${checked ? styles.iconBoxActive : halfChecked ? styles.iconBoxActiveHalf : ''
            }`}
          style={{ display: inputValue ? 'none' : 'block' }}
          id={'job-item-icon' + data?.id}
        ></span>
        <span
          id='job-item-value'
          dangerouslySetInnerHTML={{
            __html: data?.value
          }}
        ></span>
        <span
          className={styles.iconHot}
          style={{ display: data.is_popular ? 'block' : 'none' }}
          id={'job-item-hot' + data?.id}
        >
          {data.popular_text}
        </span>
      </div>
      <div
        id={'job-item-container' + data.value + data?.id}
        className={styles.rightContainer}
        onClick={onArrowClick}
      >
        {!noArrow && !inputValue && (
          <div id={'job-item-no-arrow' + data.value + data?.id} className={styles.arrow}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='12'
              height='12'
              viewBox='0 0 12 12'
              fill='none'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.64645 1.64645C3.84171 1.45118 4.15829 1.45118 4.35355 1.64645L8.35355 5.64645C8.54882 5.84171 8.54882 6.15829 8.35355 6.35355L4.35355 10.3536C4.15829 10.5488 3.84171 10.5488 3.64645 10.3536C3.45118 10.1583 3.45118 9.84171 3.64645 9.64645L7.29289 6L3.64645 2.35355C3.45119 2.15829 3.45119 1.84171 3.64645 1.64645Z'
                fill='#B8B8B8'
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  )
}
export default JobItem
