import React, { HTMLProps, useEffect, useRef, useState } from 'react';
import { LoadingOutlined, } from '@ant-design/icons';

const ImageFailedIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
  <path d="M2.33333 0.083252H15.45L7.73394 12.9434L6.42927 11.6709C6.16123 11.4094 5.71979 11.4646 5.52437 11.784L2.87619 16.1122C2.63837 16.5009 2.91809 17 3.37378 17H5.30002L3.55005 19.9166H2.33333C1.04467 19.9166 0 18.8719 0 17.5833V2.41659C0 1.12792 1.04467 0.083252 2.33333 0.083252ZM6.99967 5.33327C6.99967 4.04461 5.95501 2.99994 4.66634 2.99994C3.37768 2.99994 2.33301 4.04461 2.33301 5.33327C2.33301 6.62194 3.37768 7.66661 4.66634 7.66661C5.95501 7.66661 6.99967 6.62194 6.99967 5.33327Z" fill="#B8B8B8" />
  <path d="M21.6667 19.9166H6.55005L8.30002 17H20.6973C21.1369 17 21.4186 16.5321 21.2128 16.1436L17.1725 8.51464C16.9678 8.1281 16.4262 8.09603 16.1773 8.45571L12.5224 13.7371C12.317 14.034 11.8939 14.0749 11.6354 13.8228L10.7339 12.9434L18.45 0.083252H21.6667C22.9553 0.083252 24 1.12792 24 2.41659V17.5832C24 18.8719 22.9553 19.9166 21.6667 19.9166Z" fill="#B8B8B8" />
</svg>

export const ImageWithState = (props: HTMLProps<HTMLDivElement> & { src: string }) => {
  const { src, ...divProps } = props
  const [loadState, setLoadState] = useState(1);
  const timerRef = useRef(-1);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])
  const wrapFunWithTimeout = (func, delay = 1000 * 1) => {
    return () => {
      timerRef.current = window.setTimeout(() => {
        func()
      }, delay);
    }
  }

  return <div {...divProps}>
    <img src={src} style={{ width: '100%', height: '100%', opacity: !loadState ? 1 : 0 }}
      onLoad={wrapFunWithTimeout(() => {
        setLoadState(0)
      })}
      onError={wrapFunWithTimeout(() => {
        setLoadState(-1)
      })}
    />
    {
      Boolean(loadState) && <div style={{
        height: '100%',
        position: 'absolute',
        top: 0, left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#F5F7FB',
        borderRadius: 2
      }}>
        {loadState === 1 && <LoadingOutlined />}
        {loadState === -1 && <ImageFailedIcon />}
      </div>
    }
  </div>
}