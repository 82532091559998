'use client'
import dynamic from 'next/dynamic';
import { publishSharedData } from 'bossjob-remote/dist/clientStorage';
import { Suspense, useEffect, useMemo, useState } from 'react';

let importCssPromise = null

let importCss = () => {
  if (!importCssPromise && typeof window !== 'undefined') {
    importCssPromise = new Promise(res => {
      if ('requestIdleCallback' in window) {
        requestIdleCallback(() => res(import('@yolotechnology/bossjob-login/lib/style.css')));
      } else {
        setTimeout(() => res(import('@yolotechnology/bossjob-login/lib/style.css')), 3000); // fallback for browsers that don't support requestIdleCallback
      }
    })
  }
  return importCssPromise
}
if (typeof window !== 'undefined') {
  publishSharedData('ASSETS_BUCKET_URL', process.env.S3_BUCKET_URL)
  publishSharedData('BOSSJOB_JOBSEEKER_ENDPOINT', process.env.BOSSJOB_JOBSEEKER_ENDPOINT)
  publishSharedData('CLIENT_ID', process.env.CLIENT_ID)
  publishSharedData('CLIENT_SECRET', process.env.CLIENT_SECRET)
  publishSharedData('CF_TOKEN_ID', process.env.CF_TOKEN_ID)
  publishSharedData('ONE_CF_TOKEN_ID', process.env.ONE_CF_TOKEN_ID)
  publishSharedData('GA_TRACKING_ID', process.env.GA_TRACKING_ID)
  publishSharedData('ONE_CF_TOKEN_ID', process.env.ONE_CF_TOKEN_ID)
  publishSharedData('BOSSHUNT_URL', process.env.BOSSHUNT_URL)

}

export const LoginModal = dynamic(() => importCss().then(() => import('@yolotechnology/bossjob-login/lib/src/remote-login-modal')), { ssr: false })
export const RedirectApp = dynamic(() => import('app/components/ModalAppRedirect'), { ssr: false })
// export const RedirectApp = () => {
//   const [cssReady, setCssReady] = useState(false)
//   useEffect(() => {
//     importCss().then(() => setCssReady(true))
//   }, [])po

//   return cssReady ? <RedirectAppNormal /> : null
// }

export const GetStarted = dynamic(() => importCss().then(() => import('@yolotechnology/bossjob-login/lib/src/remote-get-started')), { ssr: false })
export const Connect = dynamic(() => importCss().then(() => import('@yolotechnology/bossjob-login/lib/src/remote-login-connect')), { ssr: false })
export const EmailPage = dynamic(() => importCss().then(() => import('@yolotechnology/bossjob-login/lib/src/remote-login-email')), { ssr: false })
export const PhonePage = dynamic(() => importCss().then(() => import('@yolotechnology/bossjob-login/lib/src/remote-login-phone')), { ssr: false })
export const MagicPage = dynamic(() => importCss().then(() => import('@yolotechnology/bossjob-login/lib/src/remote-login-magic')), { ssr: false })
export const SocialCallback = dynamic(() => importCss().then(() => import('@yolotechnology/bossjob-login/lib/src/remote-social-auth-callback')), { ssr: false })
