'use client'
import React from 'react'
import { Modal } from 'antd';
import { useRouter } from 'next/navigation';
import { getLang } from 'helpers/country';
import { useSharedData } from 'bossjob-remote/dist/hooks'

import InfoIcon from './InfoIcon';
import { getProfileCompleteStep } from './utils';
import styles from './modal.module.scss'

const CompleteProfileModal = (props) => {
  const { jobDetail = {} } = props
  const router = useRouter()
  const lang = getLang()
  const dic = useSharedData('DICTIONARY') || {}
  const { profile = {} } = dic

  const handleOk = () => {
    Promise.resolve().then(() => {
      // jobDetail?.id && sessionStorage.setItem('chatJobId', jobDetail?.id)
    }).then(() => {
      const step = getProfileCompleteStep(props?.userDetail || {})
      router.push(`/${lang}/jobseeker-complete-profile?step=${step}&from=chat&chatJobId=${jobDetail?.id}`)
      props.onOk && props.onOk()
    }).catch((err) => {
      console.log('err', err)
    })
  };

  const handleCancel = () => {
    const pathname = window.location.pathname
    // /chat-redirect/xxx
    if (pathname.includes('/chat-redirect')) {
      router.push('/')
    }
    props.onCancel && props.onCancel()
  };

  return (
    <Modal
      wrapClassName='complete-profile'
      title={null}
      open={props.open}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      closable={false}
      footer={null}
    >
      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles.title}><InfoIcon />{profile.completedModal?.title}</div>
          <p className={styles.content}>{profile.completedModal?.description}</p>
        </div>
        <div className={styles.footer}>
          <button className={styles.button + ' ' + styles.cancel} onClick={handleCancel}>{profile.cancel}</button>
          <button className={styles.button + ' ' + styles.confirm} onClick={handleOk}>{profile.completedModal?.confirm}</button>
        </div>
      </div>
    </Modal>
  )
}

export default CompleteProfileModal