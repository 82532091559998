import React, { useState } from 'react'
import { getLanguageId } from 'helpers/country'
import { S3BucketJobseeker } from 'images'
import { ChangeLangAndRegion } from '../Common/ChangeLangAndRegion'

/* Style */
import styles from '../Header.module.scss'
import { languages } from 'helpers/country.base'

const countryLanguage = ({ countryKey }) => {
  const languageId = getLanguageId()
  const [show, setShow] = useState(false)

  const languageSlug = languages.find((item) => item.id === languageId)?.slug
  const click = () => {
    setShow(true)
  }
  const countryFlagIcon =
    countryKey === 'com'
      ? `${S3BucketJobseeker}/flags/flag_inter.png`
      : `${S3BucketJobseeker}/flags/${countryKey.toUpperCase()}.png`
  return (
    <>
      <span
        onClick={click}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 40 }}
      >
        {/* <Flag /> */}
        <span
          style={{
            display: 'flex',
            width: 30,
            height: 20,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundImage: `url(${countryFlagIcon})`,
            border: '1px solid #f6f6f6'
          }}
        />
        <span className={styles.separator} style={{ padding: '0 8px' }}>
          |
        </span>
        <span className={styles.label}>{languageSlug}</span>
      </span>

      <ChangeLangAndRegion
        show={show}
        onAction={({ show }) => {
          setShow(show)
        }}
      />
    </>
  )
}

export default countryLanguage
