'use client'
import React, { useEffect, useState } from 'react'
import { Image } from 'antd'
import AWSImg from '../../images/AWS.png'
import BLImg from '../../images/BL.png'
import CEIImg from '../../images/CEI.png'
import SGCertImage from '../../images/sg_cert.png'
import IDCertImage from '../../images/id_cert.png'
import PHCertImage from '../../images/ph_cert.png'
import JPCertImage from '../../images/jp_cert.png'
import CertificateImage from '../../images/certificate.png'
import styled from '@emotion/styled'

const previewImages = {
  CertImage: {
    ph: PHCertImage,
    jp: JPCertImage,
    id: IDCertImage,
    sg: SGCertImage
  },
  CertificateImage: CertificateImage
}

const SafeInformation = (props: any) => {
  const { data, countryKey } = props
  const { AWS, BL, CEI } = data?.foot || {}

  const [visible, setVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState(undefined)

  useEffect(() => {
    // 添加全局右键菜单事件监听器
    const handleContextMenu = (e) => {
      e.preventDefault() // 阻止默认右键菜单
    }
    document.addEventListener('contextmenu', handleContextMenu)

    if (!visible) {
      // 弹框关闭，移除右键菜单事件监听器
      document.removeEventListener('contextmenu', handleContextMenu)
    }

    // 清除副作用，移除右键菜单事件监听器
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu)
    }
  }, [visible])

  const handlePreview = (type) => {
    setPreviewImage(undefined)
    setVisible(true)
    let previewImgSrc = previewImages[type]?.src || ''
    if (type === 'CertImage') {
      // 根据不同国家展示对应公司的电子执照【国际站展示新加坡的执照,无注册国家的展示新加坡执照】
      previewImgSrc = previewImages.CertImage[countryKey]?.src || previewImages.CertImage.sg.src
    }
    setPreviewImage(previewImgSrc)
  }

  return (
    <SafeInformationStyle>
      <div className='list'>
        <span>
          <Image alt={AWS} preview={false} src={AWSImg.src} width={18} height={18} />
          <span className='text'>{AWS}</span>
        </span>
        {countryKey !== 'jp' ? (
          <span onClick={() => handlePreview('CertImage')} className='m-l-xhg'>
            <Image preview={false} src={BLImg.src} width={16} alt={BL} height={18} />
            <span style={{ cursor: 'pointer' }} className='text'>
              {BL}
            </span>
          </span>
        ) : null}
        <span onClick={() => handlePreview('CertificateImage')} className='m-l-xhg'>
          <Image alt={CEI} preview={false} src={CEIImg.src} width={34} height={18} />
          <span style={{ cursor: 'pointer' }} className='text'>
            {CEI}
          </span>
        </span>
      </div>
      {previewImage && (
        <Image
          rootClassName='previewImg'
          // style={{ display: 'none', height: 0 }}
          src={previewImage}
          preview={{
            visible,
            src: previewImage,
            onVisibleChange: (value) => {
              setVisible(value)
            }
          }}
        />
      )}
    </SafeInformationStyle>
  )
}

export default SafeInformation

const SafeInformationStyle = styled.div`
  max-width: 1136px;
  margin: 0 auto;
  color: #7d7d7d;
  font-size: 14px;
  .text {
    margin-left: 10px;
    vertical-align: middle;
  }
  .previewImg {
    display: none;
  }
`
