import configuredAxios from 'helpers/configuredAxios'

import { omitBy, isNil } from 'lodash-es';

export const getNotificationList = ({ lang, notification_type, size, page }) => {
  const axios = configuredAxios('apiGo')
  const params = omitBy({ lang, size, page, notification_type }, isNil)

  return axios.get('/notification_center/notification_list?' + (new URLSearchParams(params).toString()))
}

export const readMsg = (notification_ids: (string | number)[], readAll = false) => {
  const axios = configuredAxios('apiGo')
  const data = omitBy({ notification_ids, all_read: readAll ? 1 : undefined }, isNil);

  return axios.post('/notification_center/notification_read', data)
}

export const getUnreadMsgNum = () => {
  const axios = configuredAxios('apiGo')

  return axios.get('/notification_center/unread_notification_num')
}

export const getNotificationTypeList = ({ lang }) => {
  const axios = configuredAxios('apiGo')

  return axios.get('/notification_center/notification_type_list?' + new URLSearchParams({ lang }).toString())
}