import React, { useCallback, useEffect, useRef, useState } from 'react'

import { message, Tabs } from 'antd'

import { NoMsg } from './component/NoMsg'
import { LoadingOutlined } from '@ant-design/icons'

import { getNotificationList, getUnreadMsgNum, readMsg } from './server'
import { useTypeList } from './hooks/useTypeList'

import { ListContext } from './context/ListContext'
import { useListContext } from './hooks/useListContext'

import { AllMessageIndicator } from './CONSTANT'

import styles from './index.module.scss'
import { getLang } from 'helpers/country'
import { Drawer } from './component/Drawer'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { ImageWithState } from './component/ImageWithState'
import { convertClientToBackendLang } from 'helpers/country.base'
import classNames from 'classnames'

const MessageCard = ({ observer, item, isRead }) => {
  const itemRef = useRef<HTMLDivElement>(null)
  const target = item?.see_more?.web_target
  const btnText = item?.see_more?.text
  const id = item.id
  const isReadMsg = isRead || item.is_read
  const { addReadId } = useListContext()

  useEffect(() => {
    if ((target && btnText) || isReadMsg) {
      return
    }
    const currentRef = itemRef.current
    observer.observe(currentRef)

    return () => observer.unobserve(currentRef)
  }, [observer, item, isRead])

  const hasUrl = Boolean(btnText && target)

  return (
    <div className={styles.messageItem} ref={itemRef} data-id={id}>
      <div className={styles.header}>{`${item.notification_type_name} · ${item.send_at}`}</div>
      {Boolean(item.picture_url) && (
        <ImageWithState
          style={{ width: 350, height: 140, position: 'relative' }}
          alt='img'
          src={item.picture_url}
        />
      )}
      {Boolean(item.title) && (
        <div className={styles.titleWrapper}>
          <span className={styles.title}>{item.title}</span>
          {isReadMsg ? null : <span className={styles.dot}></span>}
        </div>
      )}
      {Boolean(item.content) && (
        <div
          onClickCapture={(e) => {
            const target = e.target as HTMLElement
            if (target.tagName === 'A') {
              const href = target.getAttribute('href')
              if (href) {
                window.open(href, '_blank')
              }
              e.stopPropagation()
              e.preventDefault()
            }
          }}
          dangerouslySetInnerHTML={{
            __html: item.content
            //   `
            //  <p>Dear Users,</p>
            // <p>We have recently received reports and complaints regarding certain individuals impersonating official Bossjob staff to engage in illegal activities targeting our platform users. We would like to issue a formal statement: Bossjob is a legitimate and compliant platform that provides effective job matching services. At present, we have not released any paid services or products. Please remain vigilant and carefully verify any information you receive. If necessary, you may contact us through the official Bossjob customer service function, and we will promptly assist you in verifying the authenticity of the information.</p>
            // <p>To help our users avoid such risks, we have compiled a <a href="https://bossjob.crisp.help/en/article/guide-to-identifying-illegal-job-scams-rk0tgg/">Guide to Identifying Common Job Scams</a>.</p>
            // <p>Best regards,</p>
            // <p>Bossjob Official Team</p>`
          }}
          className={classNames(styles.content, !hasUrl ? styles.visibleAll : '')}
        ></div>
      )}
      {Boolean(btnText && target) && (
        <div className={styles.actionArea}>
          <span
            className={styles.seeMore}
            onClick={() => {
              addReadId(id, { delay: 0 })
              window.open(`${target}`, '_blank')
            }}
          >
            {btnText}
          </span>
        </div>
      )}
    </div>
  )
}

const ListLoadingIndicator = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
      <LoadingOutlined />
    </div>
  )
}

const List = ({ list, loading, page, setmsg, tab, loadmsg, total_pages, textObj }) => {
  const [observer, setObserver] = useState<IntersectionObserver>()
  const { addReadId, readIds } = useListContext()
  useEffect(() => {
    let observer = null
    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = entry.target.getAttribute('data-id')
            id && addReadId(Number(id))
            if (observer) {
              observer.unobserve(entry.target)
            }
          }
        })
      },
      { threshold: 0.1 }
    )
    setObserver(observer)
    return () => observer.disconnect()
  }, [])

  if (!observer) {
    return null
  }

  return list?.length ? (
    <div
      className={styles.messageWrapper}
      onScroll={
        loading || page >= total_pages
          ? undefined
          : (e) => {
              const target = e.currentTarget
              const { scrollHeight, scrollTop, clientHeight } = target
              if (scrollHeight > clientHeight && scrollHeight - scrollTop - clientHeight < 10) {
                if (page * 10 < list.length) {
                  setmsg((pre) => {
                    const copy = { ...pre }
                    const copyTabData = { ...copy[tab] }
                    copyTabData.page++

                    copy[tab] = copyTabData

                    return copy
                  })
                } else {
                  loadmsg()
                }
              }
            }
      }
    >
      {list.slice(0, page * 10).map((v) => {
        return (
          <MessageCard
            key={v.id}
            observer={observer}
            item={v}
            isRead={readIds.has(v.id) || readIds.has(AllMessageIndicator)}
          />
        )
      })}
      {Boolean(loading) && <ListLoadingIndicator />}
      {page >= total_pages && <div className={styles.noMoreText}>{textObj.no_more_text}</div>}
    </div>
  ) : (
    <>
      {loading ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <ListLoadingIndicator />
        </div>
      ) : (
        <NoMsg text={textObj.no_notification_text} />
      )}
    </>
  )
}

type msgType = {
  page: number
  total_pages: number
  data: any[]
}

export const MessageCenter = ({ open, onClose, onUnreadChange }) => {
  const [typeList, title] = useTypeList()
  const [tab, setTab] = useState('1')
  const [msg, setmsg] = useState<Record<string, msgType>>({})
  const [totalUnread, setTotalUnRead] = useState(0)
  const [text, setText] = useState<Record<string, string>>({})

  const [readIds, setReadIds] = useState<Set<number | string>>(new Set())

  const [loading, setLoading] = useState({})
  const langDic = useSharedData('DICTIONARY') || {}

  const getMsgList = useCallback(({ type, page }) => {
    setLoading((pre) => {
      return { ...pre, [type]: true }
    })
    getNotificationList({
      lang: convertClientToBackendLang(getLang()),
      notification_type: `${type}` === '-1' ? undefined : type,
      page,
      size: 10
    })
      .then((res) => {
        const {
          notifications,
          total_pages,
          page,
          title,
          no_more_text,
          read_all_text,
          unread_num = 0,
          no_notification_text
        } = res.data?.data
        setText({ title, no_more_text, read_all_text, no_notification_text })

        setTotalUnRead(unread_num)
        if (page === 1) {
          // reset ids when loading first page
          setReadIds(new Set())
        } else {
          setReadIds((pre) => {
            const copy = new Set(pre)
            if (!unread_num) {
              copy.add(AllMessageIndicator)
            } else if (copy.has(AllMessageIndicator)) {
              copy.delete(AllMessageIndicator)
            }
            return copy
          })
        }

        setmsg((pre) => {
          const newType = `${type}`
          const copy = { ...pre }
          const copyTypeData = { ...copy[newType] }
          copy[newType] = copyTypeData

          copyTypeData.page = page
          copyTypeData.total_pages = total_pages
          copyTypeData.data = [...(copyTypeData.data || []), ...notifications]

          return copy
        })
      })
      .finally(() => {
        setLoading((pre) => {
          return { ...pre, [type]: false }
        })
      })
  }, [])

  const readRef = useRef({
    set: new Set(),
    timer: 0
  })

  useEffect(() => {
    getUnreadMsgNum().then((res) => {
      const data = res.data.data
      onUnreadChange(data?.num || 0)
      setTotalUnRead(data?.num || 0)
    })
  }, [])

  useEffect(() => {
    if (typeList.length) {
      const defaultKey = `${typeList[0].key}`
      getMsgList({ type: defaultKey, page: 1 })
      setTab(defaultKey)
    }
  }, [typeList])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const addReadId = (id, { delay = 1000, cb = () => {} } = {}) => {
    readRef.current.set.add(id)
    let timer = readRef.current.timer
    if (timer) {
      clearTimeout(timer)
    }
    const runner = () => {
      let ids: any[] = []
      const oldSet = readRef.current.set
      if (readRef.current.timer === timer) {
        readRef.current.timer = 0
        ids = Array.from(oldSet)
        readRef.current.set = new Set()
        readMsg(ids).then(() => {
          setReadIds((pre) => {
            return new Set([...pre, ...ids])
          })
          cb?.()
        })
      }
    }
    if (!delay) {
      runner()
      return
    }
    readRef.current.timer = timer = window.setTimeout(runner, delay)
  }

  const loadmsg = () => {
    const type = tab
    const page = msg[tab].page + 1
    getMsgList({ type, page })
  }
  const isAllRead = readIds.has(AllMessageIndicator) ? true : readIds.size >= totalUnread

  useEffect(() => {
    if (isAllRead) {
      onUnreadChange(0)
    }
  }, [isAllRead])

  return (
    <>
      <Drawer
        open={open}
        onClose={() => {
          onClose(false)
        }}
      >
        <div className={styles.messageDrawerContentWrapper}>
          <div className={styles.messageDrawerContent}>
            <div className={styles.drawerHeader}>
              {/* All notification */}
              {title}
              <div
                className={styles.readAll}
                data-disabled={isAllRead}
                onClick={
                  isAllRead
                    ? undefined
                    : () => {
                        readMsg([], true).then(() => {
                          setReadIds((pre) => {
                            return new Set([...pre, AllMessageIndicator])
                          })
                          message.success(langDic?.msgCenter?.allRead || 'All read')
                        })
                      }
                }
              >
                {text.read_all_text || 'Read All'}
              </div>
            </div>
            <div className={styles.antTabWrapper}>
              <Tabs
                activeKey={tab}
                onChange={(newTab) => {
                  setTab(newTab)

                  // always load msg when tab changed
                  const listData = { data: [], page: 1, total_pages: 0 }

                  setmsg({ ...msg, [`${newTab}`]: listData })

                  if (!listData.data.length) {
                    getMsgList({ type: newTab, page: 1 })
                  }
                }}
                items={typeList}
              />
            </div>
            <ListContext.Provider
              value={{
                addReadId,
                readIds
              }}
            >
              <List
                list={msg[tab]?.data || []}
                loading={loading[tab]}
                total_pages={msg[tab]?.total_pages || 1}
                page={msg[tab]?.page || 1}
                setmsg={setmsg}
                tab={tab}
                loadmsg={loadmsg}
                key={tab}
                textObj={text}
              />
            </ListContext.Provider>
          </div>
        </div>
      </Drawer>
    </>
  )
}
