'use client'
import React, { createContext, useContext, useEffect, useState } from 'react'
import json from '../../../../dictionaries/init_language.json'
import { getDictionary } from 'get-dictionary'
import { publishSharedData } from 'bossjob-remote/dist/clientStorage'

export const languageContext = createContext<typeof json>(json)
const Provider = languageContext.Provider

const LanguageProvider = ({ children, lang }: any) => {
  const [dic, setDic] = useState(json)
  useEffect(() => {
    getDictionary(lang ?? 'en-us').then(setDic)
  }, [lang])
  useEffect(() => {
    publishSharedData('DICTIONARY', dic)
  }, [dic])
  return <Provider value={dic}>{children}</Provider>
}

export default LanguageProvider
export const useLanguage = () => {
  return useContext(languageContext)
}
