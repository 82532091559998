'use client'
import React, { useState, useEffect, useMemo } from 'react'
import { createJobAlertService } from 'store/services/alerts/createJobAlert.js'
import styles from './index.module.scss'
import Image from 'next/image'
import JobAlertsModal from './Modal'
import { ClearIcon } from 'images'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
import { displayNotification } from 'store/actions/notificationBar/notificationBar'
import { getCountryKey } from 'helpers/country'
import { v2BuildParams } from 'app/(jobs-hiring)/[lang]/jobs-hiring/interpreters/v2Encoder'
import { sortSearchValuesToString as sortSearchValue, getSearchFiltersIds as getSearchIds, getAlertData } from './getAlertData'
import { formatTemplateString, truncateWords } from 'helpers/formatter'
import { useSharedData } from 'bossjob-remote/dist/hooks'

import { EmailIcon } from './svg'

export const JobAlert = (props: any) => {
  const { searchValues,
    getSearchFiltersIds = getSearchIds,
    job_alert,
    config: newConfig,
    dictionary
  } = props

  const { search = {} } = dictionary ?? {}
  const { button_text, title } = job_alert
  const [showAlertSetting, setShowAlertSetting] = useState(false)
  const [showJobAlertsModal, setShowJobAlertsModal] = useState(false)
  const SESSION_SHOULD_HIDE_ALERT_JOBS = `${getCountryKey()}:should-hide-alert-jobs`

  const createJobAlert = async (jobAlertData) => {
    try {
      const createJobAlertPayload = { jobAlertData }
      const result = await createJobAlertService(createJobAlertPayload)
      if (result?.status >= 200 && result?.status < 300) {
        postNotification(
          'DISPATCH',
          displayNotification({
            open: true,
            severity: 'success',
            message: search?.alertJobs?.settingSucceed
          })
        )
      } else {
        postNotification(
          'DISPATCH',
          displayNotification({
            open: true,
            severity: 'error',
            message: search?.alertJobs?.settingFailed
          })
        )
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || search?.alertJobs?.somethingWrong
      postNotification(
        'DISPATCH',
        displayNotification({
          open: true,
          severity: 'error',
          message: errorMessage
        })
      )
    }
  }

  useEffect(() => {
    if (typeof sessionStorage != 'undefined') {
      setShowAlertSetting(!sessionStorage.getItem(SESSION_SHOULD_HIDE_ALERT_JOBS))
    }
  }, [])

  const closeAlertSetting = () => {
    setShowAlertSetting(false)
    if (typeof sessionStorage != 'undefined') {
      sessionStorage.setItem(SESSION_SHOULD_HIDE_ALERT_JOBS, '1')
    }
  }

  const handleSaveJobAlert = (settings: any) => {
    const paramIds = v2BuildParams(newConfig, searchValues)
    const newData = getSearchFiltersIds(paramIds)
    if (paramIds && paramIds.job_location_ids) {
      newData.location_ids = paramIds.job_location_ids
    }

    delete newData.function_job_title_ids
    const createJobAlertPayload = {
      ...settings,
      keyword: searchValues?.query ? searchValues.query : '',
      ...newData
    }
    createJobAlert(createJobAlertPayload).then(() => setShowJobAlertsModal(false))
  }

  // TODO job alert
  const sharedConfig = useSharedData('CONFIG')
  const data = useMemo(() => {
    if (!sharedConfig) return

    const a = getAlertData(searchValues, sharedConfig)
    return a
  }, [searchValues, sharedConfig])

  const viewSearchFilterString = sortSearchValue({
    ...data,
  } as any)

  const showAlertSettingModal = useMemo(() => {
    return showAlertSetting && viewSearchFilterString?.length > 0
  }, [showAlertSetting, viewSearchFilterString])

  const messageInfo = () => {
    if (!search?.alertJobs?.info || !viewSearchFilterString) return ''
    const maxWords = 100
    const newStr = truncateWords(viewSearchFilterString, maxWords)
    return formatTemplateString(search?.alertJobs?.info, {
      message: `<span title="${viewSearchFilterString}" class="${styles.jobListOptionAlertsJobs}">[${newStr}]</span>`
    })
  }

  return (
    <div
      className={
        styles.jobListOptionAlerts + ' ' + (showAlertSettingModal ? '' : styles.hideAlertSetting)
      }
    >
      <div className={styles.jobListOptionAlertsMain}>
        <div className={styles.jobListOptionAlertsLeft}>
          <div
            style={{ width: 48, height: 48, marginRight: 12 }}
            className={styles.jobListOptionAlertsImage}>
            <EmailIcon />
          </div>
          <div className={styles.jobListOptionAlertsContent}>
            <span className={styles.jobListOptionAlertsTitle}>{title}</span>
            {/* <span className={styles.jobListOptionAlertsQuestion}>
              {prefix_text} <span title={highlight_text} className={styles.jobListOptionAlertsJobs}>[{highlight_text}]</span>{suffix_text}
            </span> */}

            {messageInfo() && <span
              className={styles.jobListOptionAlertsQuestion}
              dangerouslySetInnerHTML={{ __html: messageInfo() }}
            ></span>}
          </div>
        </div>
        <div className={styles.jobListOptionAlertsRight}>
          <span
            className={styles.jobListOptionAlertsSetting}
            onClick={() => setShowJobAlertsModal(true)}
          >
            {button_text}
          </span>
        </div>
        <div className={styles.jobListOptionAlertsClosed} onClick={closeAlertSetting}>
          <Image src={ClearIcon} width={16} height={16} alt='closed' />
        </div>
      </div>

      <JobAlertsModal
        open={showJobAlertsModal}
        lang={search}
        message={viewSearchFilterString}
        handleSave={handleSaveJobAlert}
        handleClose={() => setShowJobAlertsModal(false)}
      />
    </div>
  )
}
export default JobAlert
