import ToastItem from './toastItem'
import { createRoot } from 'react-dom/client'

const createDom = (content, duration, type) => {
  const dom = document.createElement('div')
  const JSXdom = <ToastItem content={content} type={type} />
  // ReactDOM.render(JSXdom, dom)
  document.body.appendChild(dom)
  const root = createRoot(dom);

  // 使用 render 方法渲染 JSX
  root.render(JSXdom);
}

const Toast = {
  dom: null,
  success(content: string, duration?: number) {
    createDom(content, duration, 'success')
  },
  error(content: string, duration?: number) {
    createDom(content, duration, 'error')
  },
  warning(content: string, duration?: number) {
    createDom(content, duration, 'warning')
  },
  info(content: string, duration?: number) {
    createDom(content, duration, 'info')
  }
}

export default Toast
