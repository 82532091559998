import { Decimal } from 'decimal.js'
type symbolType = {
  id: number,
  symbol: string,
  rate: number,
  fullName?: string
  [k: string]: any
}

const exchangeRates = [
  {
    id: 1,
    symbol: 'usd',
    rate: 1
  },
  {
    id: 2,
    symbol: 'eur',
    rate: 0.85
  },
  {
    id: 3,
    symbol: 'cny',
    rate: 6.45
  },
  {
    id: 4,
    symbol: 'jpy',
    rate: 110
  },
  {
    id: 5,
    symbol: 'gbp',
    rate: 0.72
  },
  {
    id: 6,
    symbol: 'aud',
    rate: 1.36
  },
  {
    id: 7,
    symbol: 'cad',
    rate: 1.25
  },
  {
    id: 8,
    symbol: 'chf',
    rate: 0.92
  },
  {
    id: 9,
    symbol: 'PHP',
    rate: 7.76
  },
  {
    id: 10,
    symbol: 'nzd',
    rate: 1.42
  }
]

export function MoneyConvert({
  options = exchangeRates
}: {
  //  benchSymbol: symbolType,
  options: symbolType[]
}) {
  const benchSymbol = options.find(v => Number(v.rate) === 1)
  if (!benchSymbol) {
    throw new Error('options have not bench rate, check your options and try again later ,those options are:' + JSON.stringify(options))
  }

  const findRateById = (id) => {
    return options.find((item) => item.id === id)
  }

  const convert = (amount, sourceID, targetId) => {
    const sourceOpt = findRateById(sourceID)
    const targetOpt = findRateById(targetId)

    if (!sourceOpt || !targetOpt) {
      console.error('Invalid currency ID')
      return
    }

    const amountDecimal = new Decimal(`${amount}`).dividedBy(new Decimal(`${sourceOpt.rate}`)).mul(new Decimal(`${targetOpt.rate}`))
    console.log('@amont:', amount)
    // const benchAmount = amount / sourceRate
    // const targetAmount = benchAmount * targetRate

    return {
      value: amountDecimal.toFixed(4),
      targetOpt,
      sourceOpt
    }
  }

  return convert
}

