/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react'
import * as ReactDOM from 'react-dom'
import { map, is, pipe, filter } from 'ramda'
/* Vendor */
import classNames from 'classnames/bind'
import classNamesCombined from 'classnames'
import { useForm } from 'react-hook-form'
/* Components */
import Text from 'components/Text'
import Button from 'components/Button'
import Accordian from 'components/Accordian'
/* Helpers */
/* Style */
import styles from './JobSearchFilters.module.scss'
/* Images */
import { CloseIcon } from 'images'
// import { languageContext } from '../../providers/languageProvider'
import Image from 'next/image'
interface NavSearchFilterProps {
  urlDefaultValues: any
  // categories: any
  isShowFilter: boolean
  handleShowFilter: Function
  onResetFilter: Function
  moreFilterReset?: boolean
  isShowingEmailAlert: boolean
  setClientDefaultValues: Function
  keyword: string
  config: any
  translate: any
}
interface SearchFilters {
  title: string
  fieldName: any
  options: Array<optionsType>
  defaultOpenState?: boolean
  isNotCollapsible?: boolean
  isColumn?: boolean
  isRadioButton?: boolean
  hasMainAndSubOption?: boolean
}
type optionsType = {
  value: string
  label: string
  'seo-value'?: string
  id?: any
  key?: any
  // eslint-disable-next-line camelcase
  sub_list?: any
  translate: any
}
const NavSearchFilter = ({
  urlDefaultValues,
  isShowFilter,
  handleShowFilter,
  onResetFilter,
  moreFilterReset = false,
  isShowingEmailAlert,
  setClientDefaultValues,
  keyword,
  config,
  translate
}: NavSearchFilterProps) => {
  if (!isShowFilter) return null

  const {
    filter_reset_text,
    filter_apply_text,
    filter_header_text,
    xp_lvl_text,
    degree_text,
    industry_text,
    company_size_text,
    financing_stage_text,
    job_type_text
  } = translate

  const expLvlList = config.xp_lvls
  const eduLevelList = config.degrees
  const jobTypeList = config.job_types
  const companySizeList = config.company_sizes
  const industryList = config.industry_lists
  const financingStageList = config.company_financing_stage_lists

  const scrollY = useRef(0)
  const filterRef = useRef(null)
  const sortRef = useRef(null)
  const form = useForm()
  const { register, handleSubmit, reset } = form
  const cx = classNames.bind(styles)
  const isShowFilterClass = cx({
    isShowingEmailAlert: isShowingEmailAlert
  })

  // const [displayMobileFilters, setDisplayMobileFilters] = useState(false)

  useEffect(() => {
    if (moreFilterReset) {
      handleResetFilter()
    }
  }, [moreFilterReset])

  useEffect(() => {
    // set defaultValues after config has been initialised
    if (Object.keys(urlDefaultValues).length !== 0) {
      reset(urlDefaultValues)
    }
    // setDisplayMobileFilters(width < 769 ? true : false)
  }, [config, keyword])

  const onCloseFilter = () => {
    document.documentElement.classList.remove('modal-active')
    /* For IOS devices, restore scroll position*/
    // window.scrollTo(0, scrollY.current)
    handleShowFilter()
  }
  const handleClickedOutside = (e) => {
    // hardcoding to detect clicking on MUI component
    document.body.style.overflow = "auto";
    const isClickingOnSpecializationMUI = e.target.id.includes('specialization-option')
    const isClickingOnSort = sortRef.current === 'sort'
    if (
      filterRef.current &&
      !filterRef.current.contains(e.target) &&
      !isClickingOnSpecializationMUI &&
      !isClickingOnSort
    )
      onCloseFilter()
  }

  const syncHeight = () => {
    document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`)
  }

  useEffect(() => {
    document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`)
    window.addEventListener('resize', syncHeight)
    scrollY.current = window.pageYOffset
    // document.documentElement.classList.add('modal-active')
    document.body.style.overflow = "hidden";
    document.addEventListener('click', handleClickedOutside, true)
    return () => {
      document.removeEventListener('click', handleClickedOutside, true)
    }
  }, [])

  const handleApplyFilter = (data) => {
    onCloseFilter()
    const filtered = pipe(
      map((arr) => (is(Array)(arr) ? arr.filter((a) => a) : [])),
      // @ts-ignore
      filter((a) => a?.length)
    )(data)
    setClientDefaultValues(filtered)
  }

  const handleResetFilter = () => {
    const state = form.getValues()
    const result = {}
    Object.keys(state).forEach(key => {
      result[key] = false
    })
    reset(result, { keepValues: false })
    onResetFilter({})
    reset({})
    reset({})
  }
  // to handle filters that have 1 tier options
  const SearchFilters = ({
    title,
    fieldName,
    options,
    defaultOpenState,
    isNotCollapsible,
    isColumn
  }: SearchFilters) => {
    const isFilterColumnClass = cx({ searchFilterOptionsColumn: isColumn })
    return (
      <div className={styles.searchFilterSection}>
        <Accordian
          chevronIcon
          paddedContent
          isNotCollapsible={isNotCollapsible}
          defaultOpenState={defaultOpenState}
          title={
            <Text textStyle='lg' bold>
              {title}
            </Text>
          }
          className={styles.searchFilterAccordion}
        >
          <div className={classNamesCombined([styles.searchFilterOptions, isFilterColumnClass])}>
            {options &&
              options.map((option, i) => {
                return (
                  <React.Fragment key={i}>
                    {i === 0 && (
                      // injecting an empty checkbox fixes weird bug where selecting the second checkbox will auto select first checkbox
                      <label key='empty' style={{ display: 'none' }}>
                        <input type='checkbox' value={null} {...(register as any)(fieldName)} />
                        <Text textStyle='lg'>Empty</Text>
                      </label>
                    )}
                    <label key={i} className={styles.searchFilterOption}>
                      <input
                        type='checkbox'
                        value={option['seo_value']}
                        defaultChecked={
                          urlDefaultValues[fieldName] &&
                          urlDefaultValues[fieldName].includes(option['seo_value'])
                        }
                        {...(register as any)(fieldName)}
                      />
                      <Text textStyle='lg'>{option.value}</Text>
                    </label>
                  </React.Fragment>
                )
              })}
          </div>
        </Accordian>
      </div>
    )
  }
  const isRemotePage = window.location.href.includes('remote-jobs-hiring')

  return ReactDOM.createPortal(
    <>
      <div className={styles.modalOverlay} />
      <div className={styles.modalWrapper} aria-modal aria-hidden tabIndex={-1} role='dialog'>
        <div
          ref={filterRef}
          className={classNamesCombined([styles.searchFilter, isShowFilterClass])}
        >
          <div className={styles.searchFilterHeader}>
            <Text textStyle='lg' bold>
              {filter_header_text}
            </Text>
            <div className={styles.searchFilterClose} onClick={() => onCloseFilter()}>
              <Image src={CloseIcon} alt='logo' width='13' height='13' />
            </div>
          </div>
          <div className={styles.searchFilterBody}>
            <form className={styles.searchFilterForm}>
              {isRemotePage &&
                <SearchFilters
                  title={job_type_text} //'Job Type'
                  fieldName='jobType'
                  options={jobTypeList}
                  defaultOpenState={true}
                  isNotCollapsible={true}
                // isColumn
                />}

              <SearchFilters
                title={xp_lvl_text}
                fieldName='workExperience'
                options={expLvlList}
                defaultOpenState={true}
                isNotCollapsible={true}
              />

              <SearchFilters
                title={degree_text}
                fieldName='qualification'
                options={eduLevelList}
                defaultOpenState={true}
                isNotCollapsible={true}
              />

              {
                !isRemotePage &&
                <SearchFilters
                  title={industry_text}
                  fieldName='industry'
                  options={industryList}
                  defaultOpenState={true}
                  isNotCollapsible={true}
                />
              }

              <SearchFilters
                title={company_size_text}
                fieldName='companySizes'
                options={companySizeList}
                defaultOpenState={true}
                isNotCollapsible={true}
              />

              <SearchFilters
                title={financing_stage_text}
                fieldName='financingStages'
                options={financingStageList}
                defaultOpenState={true}
                isNotCollapsible={true}
              />
            </form>
          </div>
          <div className={styles.searchFilterFooter}>
            <div className={styles.searchFilterReset} onClick={handleResetFilter}>
              <Button>
                <Text textStyle='base' textColor='primary' bold>
                  {filter_reset_text}
                </Text>
              </Button>
            </div>
            <Button
              className={styles.searchFilterApply}
              onClick={handleSubmit(handleApplyFilter)}
              primary
            >
              <Text textStyle='base' textColor='white' bold>
                {filter_apply_text}
              </Text>
            </Button>
          </div>
        </div>
      </div>
    </>,
    document.body
  )
}
export default NavSearchFilter