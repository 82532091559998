import React from 'react'

const NoMsgIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="160" height="140" viewBox="0 0 160 140" fill="none">
    <rect x="24.2285" y="24.3125" width="111.543" height="81.3752" fill="#F4F8FF" />
    <rect x="66.7441" y="40.3452" width="55.2993" height="3" fill="#D8E9FF" />
    <rect x="66.7441" y="49.3452" width="55.2993" height="3" fill="#D8E9FF" />
    <path d="M36.3584 82C36.3584 75.9249 41.2833 71 47.3584 71C53.4335 71 58.3584 75.9249 58.3584 82C58.3584 88.0751 53.4335 93 47.3584 93C41.2833 93 36.3584 88.0751 36.3584 82Z" fill="#D8E9FF" />
    <path d="M47.3584 36.3452L58.3584 56.3452H36.3584L47.3584 36.3452Z" fill="#FFCC00" />
    <circle cx="106.913" cy="93.3978" r="3.64583" fill="white" />
    <circle cx="118.703" cy="93.3978" r="3.64583" fill="white" />
    <circle cx="130.493" cy="93.3978" r="3.64583" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M117.083 64.4104L117.083 60.0454H122.658L122.658 64.4104C132.885 65.773 140.774 74.5301 140.774 85.1299V100.459H144.955V106.034H94.7859V100.459H98.9666V85.1299C98.9666 74.5301 106.856 65.773 117.083 64.4104ZM129.625 110.214V115.789H110.115V110.214H129.625Z" fill="#2378E5" />
    <path d="M126.539 72.1724C130.179 74.5014 132.647 77.8073 134.235 81.4639" stroke="white" strokeWidth="3.65881" />
    <path d="M94.5 100.175H145.5V106.528H94.5V100.175Z" fill="#2378E5" />
  </svg>
}

export const NoMsg = ({ text = 'No notifications' }) => {
  return <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 12, alignItems: 'center' }}>
      <NoMsgIcon />
      <span style={{ color: '#7D7D7D', fontSize: 14, fontWeight: 400 }}>{text}</span>
    </div>
  </div>
}