import  { useEffect } from 'react'
// import { Snackbar, Alert } from '@mui/material'
import { message } from 'antd'

type AlertColor = 'success' | 'info' | 'warning' | 'error';

interface SnackProps {
  content: string,
  duration?: number,
  type?: AlertColor
}



const ToastItem = ({ content, type = 'success' }: SnackProps) => {

  useEffect(() => {
    
      if (type === 'success') {
        message.success(content)
      }
      else if (type === 'error') {
        message.error(content)
      }
    
  }, [ type])
  return null
}

export default ToastItem