import { useEffect, useState } from 'react';
import { getNotificationTypeList } from '../server';
import { getLang } from 'helpers/country';
import { convertClientToBackendLang } from 'helpers/country.base';

export const useTypeList = () => {
  const [typeList, setTypeList] = useState<{ key: string, label: string }[]>([]);
  const [title, setTitle] = useState('Notification')
  useEffect(() => {
    getNotificationTypeList({ lang: convertClientToBackendLang(getLang()) }).then(res => {
      const { list, title } = res.data.data

      if (Array.isArray(list)) {
        const newData = list.map(v => {
          return {
            key: `${v.id}`,
            label: v.name
          }
        })
        setTypeList(newData)
        setTitle(title)
      }
    })
  }, [])
  return [typeList, title] as const
}