import styled from '@emotion/styled'

export const DialogWrap = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);

  .popper {
    position: fixed;
    z-index: 10001;
    top: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 900px;

    .icon-close {
      cursor: pointer;
      display: flex;
      width: 46px;
      height: 46px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #fff;
      &:hover {
        background: #f2f3f5;
        svg {
          path {
            fill: #707070;
          }
        }
      }
    }

    .popper-dialog {
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      height: 500px;
      .tabs {
        display: none;
        z-index: 100;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
      .item {
        position: relative;
        display: flex;
        width: 300px;
        justify-content: center;
        .content {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          padding: 35px 30px 44px;
          .text {
            flex: 1;
            text-align: center;
            font-size: 28px;
            font-weight: 700;
            line-height: 1.2;
            span {
              display: block;
              margin-top: 5px;
              font-size: 14px;
              font-weight: normal;
              color: #515151;
            }
          }
          .switch {
            padding: 0 30px 30px;
            .field {
              & .MuiFormControl-root {
                width: 100%;
              }
              & .MuiInputBase-root {
                &:after {
                  border-width: 0;
                }
              }
              & .MuiInput-root {
                border-bottom: 1px solid #d9e0ed;
                &:before {
                  border: none;
                }
              }
              .MuiSelect-select {
                display: flex;
                color: #121212;
              }
              & .MuiSvgIcon-root {
                color: #d9e0ed;
              }
            }
          }
          .button {
            padding: 0 30px;
            button {
              cursor: pointer;
              -webkit-appearance: none;
              appearance: none;
              border: none;
              border-radius: 8px;
              width: 100%;
              height: 44px;
              color: #fff;
              font-size: 18px;
              background: #2378e5;
              // &:hover {
              //   background: #3A90FF
              // }
            }
          }
        }

        .hide {
          display: none;
        }

        .motion {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          -webkit-transform: translate3d(0, 0, 0);
          svg {
            background: #fff;
          }
        }
        #remote-motion {
          svg {
            border-radius: 8px 0 0 8px;
          }
        }
        #talents-motion {
          svg {
            border-radius: 0 8px 8px 0;
          }
        }

        .image {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          img {
            max-width: 100%;
            height: 100%;
          }
        }
      }

      .current {
        .content {
          .text {
            color: #fff;
            span {
              color: #fff;
            }
          }
          .switch {
            .field {
              .MuiSelect-select {
                color: #fefefe;
              }
            }
          }
          .button {
            button {
              color: #2378e5;
              background: #fff;
              &:hover {
                color: #579bf5;
                background: #e9f0ff;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 540px) {
    .popper {
      width: 92%;
      top: 40px;
      .popper-dialog {
        width: 100%;
        height: 575px;
        flex-direction: column;

        .item {
          display: none;
        }
        .icon-close-mobile {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 10005;
        }
        .tabs {
          display: flex;
          width: 100%;
          padding: 45px 20px 20px;
          box-sizing: border-box;
          height: min-content;
          justify-content: space-between;

          span {
            font-size: 14px;
            padding: 0 4px 10px;
            border-bottom: 1px solid transparent;
          }
          .active {
            border-bottom: 1px solid #121212;
          }
        }
        .item {
          width: 100%;
          height: 100%;
          .motion {
            display: none;
          }
          .content {
            padding-top: 95px;
            .switch {
              & .MuiInput-root {
                line-height: 1.4;
              }
              & .MuiSvgIcon-root {
                font-size: 24px;
              }
            }
          }
          .image {
            display: block;
            img {
              // max-width: 100%;
              height: 100%;
              width: 100%;
              border-radius: 8px;
            }
          }
          .button {
            button {
              color: #fff;
              background: #2378e5;
            }
          }
        }
        .current {
          display: block;
          .content {
            .text {
              color: #121212;
              span {
                color: #515151;
              }
            }
            .switch {
              .field {
                .MuiSelect-select {
                  color: #121212;
                }
              }
            }
            .button {
              button {
                font-weight: 400;
                font-size: 18px;
                color: #fff;
                background: #2378e5;
              }
            }
          }
        }
      }
    }
  }
`
