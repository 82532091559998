/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useEffect, useRef, useState } from 'react'
import Button from 'antd/lib/button'
import { useUserAgent } from 'next-useragent'
import Logo from 'images/logo.svg'
import styles from './ModalAppRedirect.module.scss'
import { CloseIcon } from 'images/index'
import { getCookie } from 'helpers/cookies'
import { getCountryKey } from 'helpers/country'
import classNames from 'classnames'
// import 'styles/globals.scss'

const ModalAppRedirect = () => {
  const userDetail = getCookie('user')

  const goToAppTime = useRef<any>()
  const [userAgent, setUserAgent] = useState(null)
  const [openAppIsLoading, setOpenAppIsLoading] = useState(false)
  const [isShowAd, setIsShowAd] = useState(true)
  const [neverCloseAd, setNeverCloseAd] = useState(false)
  const scrollTimeoutRef = useRef(null)

  useEffect(() => {
    window.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        clearTimeout(goToAppTime?.current)
        setOpenAppIsLoading(false)
      }
    })
  }, [])

  const ua = useUserAgent(globalThis?.navigator?.userAgent)

  useEffect(() => {
    setUserAgent(ua)
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      setIsShowAd(false)

      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current)
      }

      scrollTimeoutRef.current = setTimeout(() => {
        setIsShowAd(true)
      }, 2000)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current)
      }
    }
  }, [])

  const handleOpenApp = useCallback(() => {
    if (window && typeof window !== 'undefined') {
      //  adjust link

      const schema = `https://cyyj.adj.st/bossjob.ph/home?adj_t=1d2kydrr`

      // const appStoreLink = userAgent?.isIos ? APP_STORE_LINK : GOOGLE_PLAY_STORE_LINK
      window.location.replace(schema)

      const visitedPage = getCookie('visitedPage')
      const isSg = getCountryKey() === 'sg'
      if (visitedPage === 'sg-h5' && isSg) {
        window?.gtag?.('event', 'sgh5_open_app')
      }
      // Wait 2s and redirect to App Store/Google Play Store if app was not opened
      goToAppTime.current = setTimeout(() => {
        window.location.replace(schema)
      }, 3000)
    }
  }, [userDetail, userAgent])

  const handleOpenAppEvent = () => {
    // handleOpenAppCallBack ? handleOpenAppCallBack() : handleOpenApp()
    handleOpenApp()
    setOpenAppIsLoading(true)
  }

  const handleNeverCloseAd = () => {
    setNeverCloseAd(true)
  }

  return (
    // <Modal
    //   headerTitle="Bossjob is better on the app"
    //   showModal={isShowModal}
    //   // footer={null}
    //   // centered
    //   handleModal={handleModal}
    // >
    <>
      {
        !neverCloseAd && (
          <div
            className={classNames([
              styles.container,
              isShowAd ? styles.container_show : styles.container_hide
            ])}
          >
            <div className={styles.left}>
              {/* <div className={styles.closeImage} onClick={handleModal}>{<img src={CloseIcon}></img>}</div> */}
              <div className={styles.closeImage} onClick={handleNeverCloseAd}>
                {<img alt='close' src={CloseIcon}></img>}
              </div>
              <div className={styles.icon}>
                <img src={Logo} alt='Bossjob' width={40} height={40} />
              </div>
              <div className={styles.titleContainer}>
                <div className={styles.mainTitle}>Find Job.</div>
                <div className={styles.subTitle}>Talk to Boss.</div>
              </div>
            </div>
            <div className={styles.right}>
              <Button
                loading={openAppIsLoading}
                style={{
                  background: '#2378E5',
                  color: 'white',
                  borderColor: '#2378E5',
                  borderRadius: 37,
                  height: 36
                }}
                onClick={handleOpenAppEvent}
              >
                Open app
              </Button>
            </div>
          </div>
        )
        // <div className={styles.ModalAppRedirect}>
        //   <div className={styles.ModalAppRedirectOption}>
        //     <img
        //       className={styles.headerLogoImage}
        //       src={BossjobFittedLogoApp}
        //       title="Bossjob logo"
        //       alt="Bossjob logo"
        //       width="40"
        //       height="40"
        //     />
        //     <Text className={styles.BossjobLogoText}>Bossjob App</Text>
        //     <div className={styles.ModalAppRedirectOptionAction}>
        //       <Button onClick={handleOpenAppEvent} loading={openAppIsLoading}>
        //         <Text textStyle="base" bold textColor="white">
        //           Open
        //         </Text>
        //       </Button>
        //     </div>
        //   </div>
        //   <div className={styles.ModalAppRedirectOption}>
        //     <img
        //       className={styles.headerLogoImage}
        //       src={browser?.image}
        //       title="Bossjob logo"
        //       alt="Bossjob logo"
        //       width="40"
        //       height="40"
        //     />
        //     <Text className={styles.BossjobLogoText}>{browser?.title}</Text>
        //     <div className={styles.ModalAppRedirectOptionAction}>
        //       <Button onClick={() => handleModal?.()}>
        //         <Text textStyle="base" bold textColor="primaryBlue">
        //           Continue
        //         </Text>
        //       </Button>
        //     </div>
        //   </div>
        // </div>
      }
    </>
    // </Modal>
  )
}

export default ModalAppRedirect
