import { Input, InputProps } from "antd"
import React, { useState } from "react"

export const NumberInput = ({ onChange, ...rest }: InputProps & {
  onChange: (value: string | number) => void
}) => {
  const [value, setValue] = useState('')
  return <Input
    {...rest}
    value={value}
    onChange={(event) => {
      const value = event.target.value
      setValue(value);
      if (!value) {
        onChange(value)
        return
      }

      const numberValue = parseFloat(value)
      if (!isNaN(numberValue)) {
        onChange(numberValue)
      }
    }}

    onBlur={(event) => {
      let value = parseFloat(event.target.value);
      value = isNaN(value) ? null : value
      setValue(value ? `${value}` : '')
      onChange(value || 0)
    }}
  />
}