import { getCookie } from 'helpers/cookies';
import { useEffect } from 'react'

let sessionId = '';

export const useUserChange = () => {
  useEffect(() => {
    const getSid = () => (getCookie('sessionid') || '')
    if (!sessionId) {
      sessionId = getSid();
    }

    let timer;
    // let isReloading = false

    const clearTimer = () => {
      clearTimeout(timer)
    }

    const verifySession = () => {
      const newSid = getSid();
      // console.log('newSid,sessionId', newSid, sessionId)
      if ((newSid !== sessionId)) {
        console.log('reload')
        window.location.reload()
        // isReloading = true
        clearTimer()
        return
      }
    }

    const processVerify = () => {
      clearTimer()

      // timer = window.setTimeout(() => {
      //   processVerify()
      // }, 500);

      verifySession()

    }

    // processVerify()

    let handler = () => {
      if (document.visibilityState === 'visible') {
        processVerify()

      } else if (document.visibilityState === 'hidden') {
        // page hide
      }
    }

    document.addEventListener('visibilitychange', handler);
    return () => {
      document.removeEventListener('visibilitychange', handler);
      clearTimer()
    }
  }, [])
}