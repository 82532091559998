'use client'
import React from 'react'
import Script from 'next/script'
import { getCountryKey } from 'helpers/country'
import getAdForCountry from 'lib/config/adConfig'

interface adSlotProps {
  adSlot: string,
  countryKey?: string
}

const AdSlot = ({ adSlot, countryKey: propCountryKey }: adSlotProps) => {
  const countryKey = propCountryKey || getCountryKey()
  const ad = getAdForCountry(countryKey, adSlot)
  // useAdSlot({
  //   mapping: ad.mapping ? ad.mapping : {},
  //   id: ad.id,
  //   adUnit: ad.adUnit,
  //   sizes: ad.sizes,
  //   isTransitioning
  // })

  return (
    <div>
      {/* Google Adsense and Ad Manager scripts */}
      <Script
        // defer
        // strategy='lazyOnload'
        src='https://securepubads.g.doubleclick.net/tag/js/gpt.js'
      />
      <Script
        data-ad-client='ca-pub-4245733463545444'
        // strategy='lazyOnload'
        onLoad={async (...args) => {
          let count = 0
          const utilReady = new Promise(res => {
            function isReady() {
              if (window?.googletag?.apiReady) {
                res('')
              } else {
                count++
                setTimeout(() => isReady(), 10)
              }
            }
            isReady()
          })
          await utilReady
          const { googletag } = window
          if (googletag?.apiReady) {
            googletag.cmd.push(function () {
              const mapping = ad.mapping ?? {}
              const adMapping = googletag.sizeMapping()
              Object.keys(mapping).forEach((breakpoint) => {
                const isSingleSizePerMapping = mapping[breakpoint].length < 1
                adMapping.addSize(
                  [Number(breakpoint), 0],
                  isSingleSizePerMapping ? mapping[breakpoint] : [mapping[breakpoint]]
                )
              })
              const builtMapping = adMapping.build()
              googletag
                ?.defineSlot(`/21858999436/${ad.adUnit}`, ad.sizes, `div-gpt-ad-${ad.id}`)
                ?.defineSizeMapping?.(builtMapping)
                ?.addService(googletag.pubads())

              googletag.enableServices()
            })
            googletag.pubads().collapseEmptyDivs()

            googletag.cmd.push(function () {
              googletag.display(`div-gpt-ad-${ad.id}`)
            })
          }
        }}
        // defer
        src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
      >
      </Script>
      <div id={`div-gpt-ad-${ad.id}`} />
    </div>
  )
}

export default AdSlot
