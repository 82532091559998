'use client'
import { getCookie } from 'helpers/cookies'
import { check } from 'app/[lang]/chat/[chat_id]/interpreters/services/chat'
import React, { createContext, useState, useEffect } from 'react'

export const ChatInfoContext = createContext({chatInfos:[]})

const ChatInfoProvider = (props: any) => {
    const { children, recruiterIds = [] } = props
    const [chatInfos, setChatInfos] = useState([])
    const sessionid = getCookie('sessionid')
    useEffect(() => {
        setChatInfos([])
        if (recruiterIds.length && sessionid) {
            check(recruiterIds.join(','))
                .then(response => {
                    setChatInfos(response.data.data)
                })
        }
    }, [recruiterIds])
    return <ChatInfoContext.Provider
        value={{ chatInfos }}
    >
        {children}
    </ChatInfoContext.Provider>
}

export default ChatInfoProvider