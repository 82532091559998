/* eslint-disable valid-jsdoc */
import { flatMap } from 'lodash-es'

export function getAlertData(searchValues: Record<string, any>, config: Record<string, any>) {
  const industryList = config.industry_lists
  const functionsTitleList = config.function_titles
  const jobFunctionList = config.main_functions.map(e => e.children).flat()
  const locationLists = flatMap(config.location_lists, (item) => item.locations)
  const qualificationList = config.degrees

  // it is a single select
  const salaryType = (searchValues.salaryType?.join() || config.salary_type_lists?.[0]?.value || '').toLocaleLowerCase();
  // let salaryTypeIds = config.salary_type_lists.find(({value})=>value?.toLocaleLowerCase() === salaryType)?.id;
  const prefixForSalaryType = salaryType === 'monthly' ? '' : salaryType === 'daily' ? 'daily_' : 'hourly_';
  const salaryList = config[`${prefixForSalaryType}salary_range_filters`];

  const workExperienceList = config.xp_lvls
  const jobTypeList = config.job_types
  const companySizeList = config.company_sizes
  const financingStageList = config.company_financing_stage_lists

  function getValue(values, items, compareKey = 'seo-value', property = 'id') {
    return values
      ?.map?.((key) => items.find((item) => item?.[compareKey] === key)?.[property])?.join(',')
  }

  const location_ids = getValue(searchValues.location, locationLists, 'seo_value')
  const location_values = getValue(searchValues.location, locationLists, 'seo_value', 'value')
  // leve1
  const main_job_function_ids = getValue(
    searchValues?.mainFunctions,
    config.main_functions,
    'seo_value'
  ) // ?.map?.(seo => mainFunctionList.find(item => item. === seo)?.value)?.join?.(',')

  // leve2
  const job_function_ids = getValue(
    searchValues?.jobFunctions,
    jobFunctionList,
  )

  // leve3
  const function_job_title_ids = getValue(
    searchValues?.functionTitles,
    functionsTitleList,
    'seo_value',
  )

  const main_function_values = getValue(
    searchValues?.functionTitles,
    functionsTitleList,
    'seo_value',
    'value'
  )
  const industry_ids = getValue(searchValues.industry, industryList)
  const industry_values = getValue(searchValues.industry, industryList, undefined, 'value')
  const xp_lvl_ids = getValue(searchValues.workExperience, workExperienceList)
  const xp_lvl_values = getValue(
    searchValues.workExperience,
    workExperienceList,
    undefined,
    'value'
  )
  const degree_ids = getValue(searchValues.qualification, qualificationList)
  const degree_values = getValue(searchValues.qualification, qualificationList, undefined, 'value')
  const job_type_ids = getValue(searchValues.jobType, jobTypeList)
  const job_type_values = getValue(searchValues.jobType, jobTypeList, undefined, 'value')
  const salary_range_filter_ids = getValue(searchValues.salary, salaryList)
  const salary_range_values = (getValue(searchValues.salary, salaryList, undefined, 'value') || '').split(',').filter(Boolean).map(v => `${v} ${salaryType}`).join(',')
  const company_size_values = getValue(searchValues.companySizes, companySizeList, undefined, 'value')
  const company_size_ids = getValue(searchValues.companySizes, companySizeList, undefined, 'id')
  const company_financing_stage_values = getValue(searchValues.financingStages, financingStageList, 'key', 'value')
  const company_financing_stage_ids = getValue(searchValues.financingStages, financingStageList, 'key', 'id')

  return {
    location_ids,
    location_values,
    main_job_function_ids,
    job_function_ids,
    function_job_title_ids,
    main_function_values,
    industry_ids,
    industry_values,
    xp_lvl_ids,
    xp_lvl_values,
    degree_ids,
    degree_values,
    job_type_ids,
    job_type_values,
    [`${prefixForSalaryType}salary_range_filter_ids`]: salary_range_filter_ids,
    salary_range_values: salary_range_values ? salary_range_values : undefined,
    company_size_values,
    company_size_ids,
    company_financing_stage_values,
    company_financing_stage_ids,
    keyword: searchValues.query,
    salaryType: salaryType,
    // salary_type_ids:salaryTypeIds
  }
}

export const getSearchFiltersIds = (searchValues: ReturnType<typeof getAlertData>) => {
  const {
    location_ids,
    main_job_function_ids,
    job_function_ids,
    function_job_title_ids,
    industry_ids,
    xp_lvl_ids,
    degree_ids,
    job_type_ids,
    company_size_ids,
    company_financing_stage_ids,
    salary_type_ids,
    salary_range_filter_ids,
    hourly_salary_range_filter_ids,
    daily_salary_range_filter_ids
  } = searchValues

  return {
    location_ids,
    main_job_function_ids,
    job_function_ids,
    function_job_title_ids,
    industry_ids,
    xp_lvl_ids,
    degree_ids,
    job_type_ids,
    company_size_ids,
    company_financing_stage_ids,
    salary_type_ids,
    salary_range_filter_ids,
    hourly_salary_range_filter_ids,
    daily_salary_range_filter_ids,
  }
}


type ISortSearchValuesType = ReturnType<typeof getAlertData> & {
  company_verified_values: string[]
}

/**
 * generate the string of filters
 * @param values 
 * @returns 
 */
export const sortSearchValuesToString = (values: ISortSearchValuesType) => {

  const {
    location_values,
    main_function_values,
    industry_values,
    xp_lvl_values,
    degree_values,
    job_type_values,
    salary_range_values,
    company_size_values,
    company_financing_stage_values,
    keyword, company_verified_values,
  } = values
  const sortArray = [
    location_values, main_function_values,
    job_type_values,
    industry_values, xp_lvl_values,
    degree_values, company_size_values,
    company_financing_stage_values,
    keyword, company_verified_values,
    salary_range_values,
  ].filter(Boolean)
  const searchString = sortArray.join('、').replace(/,/g, '/')

  return searchString
}