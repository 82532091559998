'use client'
import React from 'react'
import { usePathname } from 'next/navigation'

import styles from '../Footer.module.scss'
import Copyright from './Mobile/Copyright'

const Mobile = (props: any) => {
  const { data, countryKey } = props
  const pathName = usePathname()

  return (
    <div className={styles.footerMobileContainer}>
      {/* <AboutLink data={data} {...restProps}/>

      <TalentsLink data={data} {...restProps}/>

      <RecruiterLink data={data} {...restProps}/>

      <PopularJobsLink data={data} {...restProps} /> */}
      {/* <Company data={data} /> */}
      {/* <DownloadApp data={data} /> */}
      {/* <SocialLink data={data} /> */}
      {!pathName.includes('/job') ? <Copyright data={data} countryKey={countryKey} /> : null}
    </div>
  )
}

export default Mobile
