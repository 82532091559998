import React, { useState, useRef, useEffect } from 'react'

/* Vendors */
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'

/* Components */
import Modal from 'components/Modal'
import Text from 'components/Text'

/* Service */
import { fetchUserShare } from 'store/services/users/share'

/* Styles */
import styles from './ModalShare.module.scss'

/* Images */
import { FacebookIcon, LinkedinIcon, CopyIcon } from 'images'
import { useSharedData } from 'bossjob-remote/dist/hooks'
interface ModalShareProps {
  jobDetailUrl?: string
  isShowModalShare?: boolean
  handleShowModalShare?: Function
  selectedJob?: any
  dictionary?: any
}
const ModalShare = ({
  jobDetailUrl: url,
  isShowModalShare,
  handleShowModalShare,
  selectedJob,
  dictionary
}: ModalShareProps) => {

  const dic = useSharedData('DICTIONARY') ?? dictionary ?? { jobDetail: {} }
  const shareModal = dic?.jobDetail?.shareModal ?? {}
  const jobLinkRef = useRef(null)
  const [isDoneCopy, setIsDoneCopy] = useState(false)
  // this share feature is no needed login, but it's better if user login
  const meInfo = useSharedData('ME')
  const [jobDetailUrl, setJobDetailUrl] = useState('')
  useEffect(() => {
    if (typeof window != 'undefined') {
      setJobDetailUrl(`${location?.origin}${url}`)
    }
  }, [url])

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link)
    setIsDoneCopy(true)
    setTimeout(() => {
      setIsDoneCopy(false)
    }, 5000)
  }

  const tokenData = () => {
    if (!meInfo?.id) {
      return
    }
    const data = {
      jobseeker_id: meInfo.id,
      recruiter_id: selectedJob.recruiter.id,
      company_id: selectedJob.company.id,
      job_id: selectedJob.id
    }

    fetchUserShare(data)
      .then(({ status }: any) => {
        if (status === 200) {
          //
        }
      })
      .catch(() => {
        //
      })
  }

  return (
    <Modal
      headerTitle={shareModal.title}
      showModal={isShowModalShare}
      handleModal={handleShowModalShare}
    >
      <div className={styles.ModalShare}>
        <div className={styles.ModalShareList}>
          <div className={styles.ModalShareItem} onClick={tokenData}>
            <FacebookShareButton url={jobDetailUrl} className={styles.ModalShareItemLink}>
              <img
                src={FacebookIcon}
                alt='facebook'
                height='56px'
                width='56px'
                className={styles.ModalShareItemImg}
              />
              <Text textStyle='base'>{shareModal.facebook}</Text>
            </FacebookShareButton>
          </div>
          <div className={styles.ModalShareItem} onClick={tokenData}>
            <TwitterShareButton url={jobDetailUrl} className={styles.ModalShareItemLink}>

              <TwitterIcon />
              <Text textStyle='base' textColor='warmgrey'>
                {shareModal.twitter}
              </Text>
            </TwitterShareButton>
          </div>
          <div className={styles.ModalShareItem} onClick={tokenData}>
            <LinkedinShareButton url={jobDetailUrl} className={styles.ModalShareItemLink}>
              <img
                src={LinkedinIcon}
                alt='linkedIn'
                height='56px'
                width='56px'
                className={styles.ModalShareItemImg}
              />
              <Text textStyle='base' textColor='warmgrey'>
                {shareModal.linkedin}
              </Text>
            </LinkedinShareButton>
          </div>
        </div>
        <div className={styles.ModalShareFooter} onClick={tokenData}>
          <Text textStyle='lg'> {shareModal.link}</Text>
          {isDoneCopy ? (
            <div className={styles.ModalShareFooterTooltip}>
              <Text textStyle='sm' textColor='white'>
                {shareModal.linkCopied}
              </Text>
            </div>
          ) : null}
          <div className={styles.ModalShareFooterLink}>
            <input
              value={jobDetailUrl}
              ref={jobLinkRef}
              onClick={() => jobLinkRef.current.select()}
              className={styles.ModalShareFooterLinkText}
              readOnly
            />
            <div
              onClick={() => handleCopyLink(jobDetailUrl)}
              className={styles.ModalShareFooterCopy}
            >
              <img src={CopyIcon} alt='close' height='18px' width='18px' />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
const TwitterIcon = () => <svg width="55" height="55" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 27.5C0 42.6878 12.3122 55 27.5 55C42.6878 55 55 42.6878 55 27.5C55 12.3122 42.6878 0 27.5 0C12.3122 0 0 12.3122 0 27.5Z" fill="black" />
  <path d="M15.5518 16.3281H23.2179L28.662 23.7851L35.2681 16.3281H37.6826L29.7523 25.278L39.5312 38.6719H31.8669L25.5495 30.0202L17.8833 38.6719H15.4688L24.4592 28.5274L15.5518 16.3281ZM19.249 18.1901L32.8743 36.8099H35.834L22.2087 18.1901H19.249Z" fill="white" />
</svg>


export default ModalShare
