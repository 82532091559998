'use client'
import React, {
  useRef,
  //  useEffect 
} from 'react'
import styles from './index.module.scss'
import { pushToResume } from 'helpers/push'
import Lottie from "lottie-react";
// import lottie from "lottie-web"
import excellentResumeData from "./excellentResume.json";
// import { throttle } from 'lodash-es'

const ExcellentResumeBanner = ({ advertisingLink }) => {
  const container = useRef(null)
  const handleClick = () => {
    pushToResume()
  }

  // useEffect(() => {

  //   const loadAnimation = () => {
  //     const animation = lottie.loadAnimation({
  //       container: container.current,
  //       renderer: 'svg',
  //       loop: true,
  //       autoplay: true,
  //       animationData: excellentResumeData
  //     })
  //     return animation;
  //   }

  //   loadAnimation()

  //   // 加载动画
  //   let animation = loadAnimation();

  //   const throttledResize = throttle(() => {
  //     // 当容器尺寸发生变化时，重新加载动画
  //     animation.destroy();
  //     animation = loadAnimation();
  //   }, 300);

  //   // 创建一个新的 ResizeObserver 实例
  //   const resizeObserver = new ResizeObserver(throttledResize);

  //   // 开始观察容器的尺寸变化
  //   resizeObserver.observe(container.current);

  //   return () => {
  //     resizeObserver.disconnect();
  //     animation.destroy();
  //     throttledResize.cancel(); // 取消节流函数的任何尚未执行的调用
  //   };
  // }, [])

  return (
    <>
      <div className={styles.container} style={{ height: 400 }} onClick={handleClick}>
        <div ref={container}>
          <Lottie animationData={excellentResumeData} loop={true} />
        </div>
        <span style={{ fontSize: '26px', marginBottom: '10px', textAlign: 'center', lineHeight: 1 }}>{advertisingLink.excellentResume}</span>
        <span style={{ fontSize: '18px' }}>{advertisingLink.openTheDoorToSuccess}</span>
        <span className={styles.button}>{advertisingLink.createMyResume}</span>
      </div>
    </>

  )
}
export default ExcellentResumeBanner
