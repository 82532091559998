import * as React from "react"
const ArrowIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M11.417 5.07a.667.667 0 1 0-.943.943l2.195 2.195H2.28a.667.667 0 0 0 0 1.334h10.39l-2.195 2.195a.667.667 0 0 0 .943.943l3.333-3.334a.667.667 0 0 0 0-.942L11.417 5.07Z"
    />
  </svg>
)
export default ArrowIcon
