'use client'
import React, { useContext, PropsWithChildren, createContext, useEffect } from 'react';
import { ManageProfileData, fetchUserOwnDetail } from './service';
import { publishSharedData } from 'bossjob-remote/dist/clientStorage'
import { useDispatch } from 'react-redux';
import { fetchAppliedJobDetailSuccess } from 'store/actions/jobs/fetchAppliedJobDetail';
import { useSharedData } from 'bossjob-remote/dist/hooks';
import { getCookie, handleUserCookiesConfig, setCookie, userKey } from 'helpers/cookies';
interface ProviderData {
	profile?: ManageProfileData
	fetchProfile?: () => void
}

const MangeProfile = createContext<
	ProviderData | undefined
>(undefined);

interface Props extends PropsWithChildren<ProviderData> {
	name?: any
}
export function ProfileProvider(
	{ children, ...props }: Props) {
	const profile = useSharedData('ME')
	const dispatch = useDispatch()
	const sessionid = getCookie('sessionid')
	const fetchProfile = () => {
		fetchUserOwnDetail().then(profile => {
			publishSharedData('ME', profile?.data)
			dispatch(fetchAppliedJobDetailSuccess(profile))
		})
	};
	useEffect(() => {
		if (sessionid) {
			fetchProfile()
		}
	}, [sessionid])
	useEffect(() => {
		if (profile) {
			const userCookies = handleUserCookiesConfig(profile)
			setCookie(userKey, userCookies)

		}
	}, [profile])
	return (
		<MangeProfile.Provider value={{ ...props, profile, fetchProfile }}>
			{children}
		</MangeProfile.Provider>
	);
}

export function useProfileData() {
	const context = useContext(MangeProfile);
	if (context === undefined) {
		throw new Error('useManageProfileData must be used within a MangeProfileProvider');
	}
	return context;
}
