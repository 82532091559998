'use client'
import React, { createContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchConfigRequest } from 'store/actions/config/fetchConfig'
import { fetchSkillConfigService } from 'store/services/config/fetchSkills'
import { publishSharedData } from 'bossjob-remote/dist/clientStorage'
import { getLang } from 'helpers/country'
export const ConfigContext = createContext<any>({})
const Provider = ConfigContext.Provider

export const selfJobFunctionOptionsKey =
  Symbol('__jobFunctionOptions__') || '__jobFunctionOptions__'

const ConfigProvider = ({ children }: any) => {
  const dispatch = useDispatch()
  const [jobFunctionOptions, setJobFunctionOptions] = useState([])
  const config = useSelector((store: any) => store.config?.config?.response)

  const fetchSkillFunctions = async () => {
    try {
      const options = await fetchSkillConfigService(getLang())
      setJobFunctionOptions(options)
      config[selfJobFunctionOptionsKey] = options
    } catch (error) { }
  }

  useEffect(() => {
    dispatch(fetchConfigRequest())
    fetchSkillFunctions()
  }, [])

  useEffect(() => {
    if (jobFunctionOptions) {
      config[selfJobFunctionOptionsKey] = jobFunctionOptions
    }
    publishSharedData('CONFIG', config)
  }, [config, jobFunctionOptions])

  return <Provider value={config}>{children}</Provider>
}
export default ConfigProvider
