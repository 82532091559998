'use client'
import React, { useState, useEffect, useMemo } from 'react'
import { createJobAlertService } from 'store/services/alerts/createJobAlert.js'
import styles from './index.module.scss'
import { getAlertData as getAlertDataFromSearchValue, sortSearchValuesToString as sortSearchValue, getSearchFiltersIds as getSearchIds } from './getAlertData'
import Image from 'next/image'
import JobAlertsModal from './Modal'
import { ClearIcon } from 'images'
import { formatTemplateString, truncateWords } from 'helpers/formatter'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
import { displayNotification } from 'store/actions/notificationBar/notificationBar'
import { getCountryKey } from 'helpers/country'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { isEmpty } from 'lodash-es'
import { buildParams } from 'app/(jobs-hiring)/[lang]/jobs-hiring/interpreters/encoder'

const EmailIcon = () => {
  return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M42.0486 17.8176C42.5327 18.2494 42.4839 19.0208 41.9491 19.3881L24 31.7173L6.05087 19.3881C5.51613 19.0208 5.46727 18.2494 5.95138 17.8176L21.3373 4.09257C22.8545 2.73916 25.1455 2.73917 26.6627 4.09257L42.0486 17.8176Z" fill="url(#paint0_linear_3224_18648)" />
    <rect x="5" y="17.9771" width="38" height="24.0229" rx="2" fill="#BFD4FF" />
    <path d="M10.55 11.2979C10.55 10.1933 11.4455 9.29785 12.55 9.29785H35.4497C36.5542 9.29785 37.4497 10.1933 37.4497 11.2979V37.5914C37.4497 38.696 36.5542 39.5914 35.4497 39.5914H12.55C11.4455 39.5914 10.55 38.696 10.55 37.5914V11.2979Z" fill="#FCFDFF" />
    <path d="M14.123 14.4477C14.123 14.1163 14.3917 13.8477 14.723 13.8477H18.9923C19.3237 13.8477 19.5923 14.1163 19.5923 14.4477C19.5923 14.779 19.3237 15.0477 18.9923 15.0477H14.723C14.3917 15.0477 14.123 14.779 14.123 14.4477Z" fill="#99BEFD" />
    <path d="M14.123 16.8476C14.123 16.5162 14.3917 16.2476 14.723 16.2476H30.2071C30.5385 16.2476 30.8071 16.5162 30.8071 16.8476C30.8071 17.1789 30.5385 17.4476 30.2071 17.4476H14.723C14.3917 17.4476 14.123 17.1789 14.123 16.8476Z" fill="#99BEFD" />
    <path d="M14.123 19.2475C14.123 18.9161 14.3917 18.6475 14.723 18.6475H25.1891C25.5204 18.6475 25.7891 18.9161 25.7891 19.2475C25.7891 19.5788 25.5204 19.8475 25.1891 19.8475H14.723C14.3917 19.8475 14.123 19.5788 14.123 19.2475Z" fill="#99BEFD" />
    <path d="M14.123 21.6479C14.123 21.3165 14.3917 21.0479 14.723 21.0479H28.0333C28.3647 21.0479 28.6333 21.3165 28.6333 21.6479C28.6333 21.9792 28.3647 22.2479 28.0333 22.2479H14.723C14.3917 22.2479 14.123 21.9792 14.123 21.6479Z" fill="#99BEFD" />
    <mask id="mask0_3224_18648" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="5" y="17" width="38" height="25">
      <rect x="5" y="17.9771" width="38" height="24.0229" rx="2" fill="#CBDCFE" />
    </mask>
    <g mask="url(#mask0_3224_18648)">
      <path d="M5 17.9771L28.287 32.69L43 42H5V17.9771Z" fill="url(#paint1_linear_3224_18648)" />
      <path d="M43 17.9771L19.713 32.69L5 42H43V17.9771Z" fill="url(#paint2_linear_3224_18648)" />
    </g>
    <path d="M31 37C31 32.5817 34.5817 29 39 29C43.4183 29 47 32.5817 47 37C47 41.4183 43.4183 45 39 45H32C31.4477 45 31 44.5523 31 44V37Z" fill="url(#paint3_linear_3224_18648)" />
    <path d="M35 37L38 40L43 35" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <defs>
      <linearGradient id="paint0_linear_3224_18648" x1="24" y1="1.71729" x2="24" y2="20.1021" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BDD2FF" />
        <stop offset="1" stop-color="#94B9FF" />
      </linearGradient>
      <linearGradient id="paint1_linear_3224_18648" x1="24" y1="17.9771" x2="24" y2="42" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DBE6FB" />
        <stop offset="1" stop-color="#CBDCFB" />
      </linearGradient>
      <linearGradient id="paint2_linear_3224_18648" x1="24" y1="17.9771" x2="24" y2="42" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DDE7FF" />
        <stop offset="1" stop-color="#DEE8FF" />
      </linearGradient>
      <linearGradient id="paint3_linear_3224_18648" x1="31" y1="33.3077" x2="48.0545" y2="35.1297" gradientUnits="userSpaceOnUse">
        <stop stop-color="#79B4FF" />
        <stop offset="1" stop-color="#297DE8" />
      </linearGradient>
    </defs>
  </svg >

}

export const JobAlert = (props: any) => {
  const { searchValues, initialConfig,
    getAlertData = getAlertDataFromSearchValue,
    sortSearchValuesToString = sortSearchValue,
    getSearchFiltersIds = getSearchIds
  } = props
  const sharedConfig = useSharedData('CONFIG')
  const config = isEmpty(sharedConfig) ? initialConfig : sharedConfig
  const data = useMemo(() => {
    if (isEmpty(config)) {
      return {}
    }
    const a = getAlertData(searchValues, config)
    // replace 
    return a
  }, [searchValues, config])
  const [showJobAlertsModal, setShowJobAlertsModal] = useState(false)
  const params = buildParams(config, searchValues)
  const [showAlertSetting, setShowAlertSetting] = useState(false)

  const { search = {} } = useSharedData('DICTIONARY') ?? {}
  const SESSION_SHOULD_HIDE_ALERT_JOBS = `${getCountryKey()}:should-hide-alert-jobs`

  const companyVerifiedList = [
    {
      key: 'verified-companies',
      ['seo-value']: 'verified-companies',
      value: search?.searchModal?.viewVerifiedCompanies,
      label: 'View verified companies'
    }
  ]

  const companyVerifiedValues = searchValues.verifiedCompany
    ? (searchValues.verifiedCompany || []).map((val) => {
      const findItem = companyVerifiedList.filter((item) => item['seo-value'] === val)
      return findItem[0]?.value || val
    })
    : undefined

  const viewSearchFilterString = sortSearchValuesToString({
    ...data,
    company_verified_values: companyVerifiedValues
  } as any)

  const createJobAlert = async (jobAlertData) => {
    try {
      const createJobAlertPayload = { jobAlertData }
      const result = await createJobAlertService(createJobAlertPayload)
      if (result?.status >= 200 && result?.status < 300) {
        postNotification(
          'DISPATCH',
          displayNotification({
            open: true,
            severity: 'success',
            message: search?.alertJobs?.settingSucceed
          })
        )
      } else {
        postNotification(
          'DISPATCH',
          displayNotification({
            open: true,
            severity: 'error',
            message: search?.alertJobs?.settingFailed
          })
        )
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || search?.alertJobs?.somethingWrong
      postNotification(
        'DISPATCH',
        displayNotification({
          open: true,
          severity: 'error',
          message: errorMessage
        })
      )
    }
  }

  useEffect(() => {
    if (typeof sessionStorage != 'undefined') {
      setShowAlertSetting(!sessionStorage.getItem(SESSION_SHOULD_HIDE_ALERT_JOBS))
    }
  }, [])

  const closeAlertSetting = () => {
    setShowAlertSetting(false)
    if (typeof sessionStorage != 'undefined') {
      sessionStorage.setItem(SESSION_SHOULD_HIDE_ALERT_JOBS, '1')
    }
  }

  const showAlertSettingModal = useMemo(() => {
    return showAlertSetting && viewSearchFilterString?.length > 0
  }, [showAlertSetting, viewSearchFilterString])

  const messageInfo = () => {
    if (!search?.alertJobs?.info || !viewSearchFilterString) return ''
    const maxWords = 100
    const newStr = truncateWords(viewSearchFilterString, maxWords)
    return formatTemplateString(search?.alertJobs?.info, {
      message: `<span title="${viewSearchFilterString}" class="${styles.jobListOptionAlertsJobs}">[${newStr}]</span>`
    })
  }

  const handleSaveJobAlert = (settings: any) => {
    const data = getAlertData(searchValues, config)
    const newData = getSearchFiltersIds(data)

    const createJobAlertPayload = {
      ...settings,
      keyword: params?.query ? params.query : '',
      is_company_verified: (params.is_company_verified ? '1' : undefined) ?? 'all',
      ...newData
    }
    createJobAlert(createJobAlertPayload).then(() => setShowJobAlertsModal(false))
  }

  return (
    <div
      className={
        styles.jobListOptionAlerts + ' ' + (showAlertSettingModal ? '' : styles.hideAlertSetting)
      }
    >
      <div className={styles.jobListOptionAlertsMain}>
        <div className={styles.jobListOptionAlertsLeft}>
          {/* <Image
            src={UploadDocIcon}
            alt='send email'
            width={48}
            height={48}
            className={styles.jobListOptionAlertsImage}
          /> */}
          <div
            // alt='send email'
            style={{ width: 48, height: 48, marginRight: 12 }}
            // width={48}
            // height={48}
            className={styles.jobListOptionAlertsImage}>
            <EmailIcon />
          </div>
          <div className={styles.jobListOptionAlertsContent}>
            <span className={styles.jobListOptionAlertsTitle}>{search?.alertJobs?.title}</span>
            <span
              className={styles.jobListOptionAlertsQuestion}
              dangerouslySetInnerHTML={{ __html: messageInfo() }}
            ></span>
          </div>
        </div>
        <div className={styles.jobListOptionAlertsRight}>
          <span
            className={styles.jobListOptionAlertsSetting}
            onClick={() => setShowJobAlertsModal(true)}
          >
            {search?.alertJobs?.setting}
          </span>
        </div>
        <div className={styles.jobListOptionAlertsClosed} onClick={closeAlertSetting}>
          <Image src={ClearIcon} width={16} height={16} alt='closed' />
        </div>
      </div>

      <JobAlertsModal
        open={showJobAlertsModal}
        lang={search}
        message={viewSearchFilterString}
        handleSave={handleSaveJobAlert}
        handleClose={() => setShowJobAlertsModal(false)}
      />
    </div>
  )
}
export default JobAlert
