export const getProfileCompleteStep = (userDetail) => {
  const {
    is_job_preferences,
    is_basic_info,
    is_work_experiences,
    is_educations,
    is_resume,
  } = userDetail || {}

  if (!is_job_preferences) {
    return 1
  }

  if (!is_basic_info) {
    return 2
  }

  if (!is_work_experiences || !is_educations || !is_resume) {
    return 3
  }
}
