'use client'
import React, { useMemo } from 'react'
import { isMobileDevice } from 'helpers/utilities'
import { useSharedData } from 'bossjob-remote/dist/hooks'

import { CardIcon, MobileCardIcon } from './CardIcon'
import ClientButton from './ClientButton'
// import ClientClose from './ClientClose'

import styles from './index.module.scss'

export const CompleteProfileCard = () => {

  const isMobile = isMobileDevice()

  const userDetail = useSharedData('ME')
  const dic = useSharedData('DICTIONARY') || {}
  // console.log('langDic::', dic)
  const { profile = {} } = dic

  const show = useMemo(() => {
    if (!userDetail) {
      return false
    }
    return !userDetail?.is_profile_completed
  }, [userDetail])

  if (isMobile && show) {
    return (
      <div className={styles.mobile}>
        <div className={styles.mobile_content}>
          <div className={styles.title}>{profile.completedCard?.title}</div>
          <p className={styles.description + ' ' + styles.mobile_description}>{profile.completedCard?.description}</p>
          <div className={styles.mobile_button}><ClientButton dictionary={dic} userDetail={userDetail} /></div>
        </div>
        <div className={styles.mobile_icon}>
          <MobileCardIcon />
        </div>
      </div>
    )
  }

  return (
    show ? (
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.icon}>
            <CardIcon />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>{profile.completedCard?.title}</div>
            <p className={styles.description}>{profile.completedCard?.description}</p>
          </div>
        </div>
        <div className={styles.right}>
          <ClientButton dictionary={dic} userDetail={userDetail} />
        </div>
        {/* <div className={styles.close}>
          <ClientClose />
        </div> */}
      </div>
    ) : null
  )
}

export default CompleteProfileCard