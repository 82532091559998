'use client'

import React from 'react'

import { getProfileCompleteStep } from './utils'
import ArrowIcon from './ArrowIcon'

import styles from './index.module.scss'
import { useRouter } from 'next/navigation'
import { getLang } from 'helpers/country'

const Button = (props: {
  userDetail: any
  dictionary: any
}) => {

  const { userDetail = {}, dictionary = {} } = props

  const router = useRouter()
  const lang = getLang()
  const { profile = {} } = dictionary


  const handleClick = () => {
    const step = getProfileCompleteStep(userDetail)
    // console.log('click', { userDetail, step, lang })
    if (!userDetail?.is_job_preferences) {
      const { pathname, search } = window.location
      router.push(`/${lang}/jobseeker-complete-profile?step=${step}&from=chat`)
      sessionStorage.setItem('redirectPage', `${pathname}${search}`)
    } else {
      router.push(`/${lang}/jobseeker-complete-profile?step=${step}`)
    }
  }

  return (
    <button onClick={handleClick} className={styles.button}>{profile.completedCard?.button} <ArrowIcon className={styles.arrow} /></button>
  )
}

export default Button