'use client'
import React from 'react'

const ShowSalary = ({ salary_info, size = 14, showTip = true }: any) => {
  const { text, type_text } = salary_info || {}

  return (
    <>
      {text}
      {showTip && type_text && (
        <span
          style={{
            color: '#2378e5',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px'
          }}
        >
          [{type_text}]
        </span>
      )}
    </>
  )
}

export default ShowSalary
