'use client'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import WestIcon from '@mui/icons-material/West'
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import dynamic from 'next/dynamic'

const ArrowForwardIosIcon = dynamic(() => import('@mui/icons-material/ArrowForwardIos'), { ssr: false })
const ArrowBackIosIcon = dynamic(() => import('@mui/icons-material/ArrowBackIos'), { ssr: false })
export { FavoriteBorderIcon, FavoriteIcon, WestIcon, ArrowForwardIosIcon,ArrowBackIosIcon }
