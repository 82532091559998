import React from "react"
import classNames from 'classnames'

export const IconCloseWrap = ({ onClose, isMobile }) => {
  return (
    <div
      className={classNames({
        'icon-close': true,
        'icon-close-mobile': isMobile
      })}
      onClick={onClose}
    >
      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='15' fill='none'>
        <path
          d='M9.78833 7.23147L15.1329 2.06604C15.3677 1.83908 15.4997 1.53124 15.4997 1.21026C15.4997 0.88928 15.3677 0.581445 15.1329 0.354477C14.8981 0.127509 14.5795 6.3273e-09 14.2474 0C13.9153 -6.3273e-09 13.5968 0.127509 13.362 0.354477L8.0174 5.5199L2.67283 0.354477C2.55655 0.242094 2.4185 0.152947 2.26658 0.0921262C2.11465 0.031305 1.95181 3.13296e-09 1.78737 0C1.62292 -3.13296e-09 1.46009 0.031305 1.30816 0.0921262C1.15623 0.152947 1.01818 0.242094 0.901904 0.354477C0.785623 0.46686 0.693383 0.600278 0.630453 0.747114C0.567522 0.893949 0.535132 1.05133 0.535132 1.21026C0.535132 1.36919 0.567522 1.52657 0.630453 1.67341C0.693383 1.82024 0.785623 1.95366 0.901904 2.06604L6.25021 7.23147L0.905643 12.3969C0.781265 12.5072 0.681242 12.6406 0.611586 12.7892C0.541931 12.9378 0.504081 13.0985 0.500312 13.2615C0.496543 13.4246 0.526934 13.5868 0.589656 13.7382C0.652379 13.8897 0.746137 14.0273 0.865297 14.1428C0.984457 14.2583 1.12656 14.3494 1.28306 14.4105C1.43956 14.4716 1.60722 14.5015 1.77598 14.4984C1.94473 14.4952 2.11109 14.4592 2.26505 14.3923C2.41901 14.3255 2.5574 14.2292 2.67189 14.1094L8.01647 8.94394L13.362 14.1094C13.4783 14.2217 13.6164 14.3109 13.7684 14.3717C13.9204 14.4325 14.0833 14.4637 14.2478 14.4637C14.4123 14.4637 14.5751 14.4323 14.7271 14.3714C14.879 14.3106 15.0171 14.2213 15.1334 14.1089C15.2496 13.9965 15.3419 13.863 15.4048 13.7161C15.4677 13.5692 15.5 13.4118 15.5 13.2528C15.5 13.0938 15.4675 12.9364 15.4045 12.7896C15.3415 12.6427 15.2492 12.5093 15.1329 12.3969L9.78833 7.23147Z'
          fill='#BCBCBC'
        />
      </svg>
    </div>
  )
}
