import React from 'react'
import { Image } from 'antd'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'

interface BrandPopupProps {
  data: any
}

const BrandPopup: React.FC<BrandPopupProps> = ({ data }) => {
  return (
    <>
      <Global styles={GlobalStyles} />
      <BrandPopupStyle>
        <div className='popup-wrap'>
          <div className='block-top'>
            <img className='logo' src={data.logo_url} alt="img" />
            <div className='top-right'>
              <div className='brand-name'>{data.title}</div>
              <div className='brand-slogan'>{data.sub_title}</div>
            </div>
          </div>
          <div className='brand-intro'>
            {data.description}
          </div>
          {
            data?.pictures?.length ? <div className='related-pictures'>
              <Image.PreviewGroup>
                {
                  data.pictures.map(item =>
                    <span className='img'>
                      <Image
                        src={item}
                        preview={{
                          mask: (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <PreviewIcon />
                            </div>
                          ),
                        }}
                      />
                    </span>
                  )
                }
              </Image.PreviewGroup>
            </div> : null
          }
        </div>
      </BrandPopupStyle>
    </>
  );
};

export default BrandPopup

const PreviewIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.5578 16.9965C18.8733 15.4212 19.6666 13.3906 19.6666 11.1771C19.6666 6.16049 15.5999 2.09375 10.5833 2.09375C5.56674 2.09375 1.5 6.16049 1.5 11.1771C1.5 16.1936 5.56674 20.2604 10.5833 20.2604C12.8452 20.2604 14.9158 19.4321 16.505 18.0649L20.4761 21.9423C20.7725 22.2317 21.2473 22.226 21.5367 21.9296C21.826 21.6333 21.8204 21.1584 21.524 20.869L17.5578 16.9965ZM15.4277 17.0116C14.1139 18.1036 12.4253 18.7604 10.5833 18.7604C6.39516 18.7604 3 15.3652 3 11.1771C3 6.98891 6.39516 3.59375 10.5833 3.59375C14.7715 3.59375 18.1666 6.98891 18.1666 11.1771C18.1666 12.9837 17.5349 14.6427 16.4803 15.9453C16.3404 16.1181 16.193 16.2846 16.0388 16.4444C16.0194 16.4645 15.9998 16.4845 15.9802 16.5043C15.8046 16.6823 15.6202 16.8516 15.4277 17.0116Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.1605 11.854C14.5748 11.854 14.9105 11.5182 14.9105 11.104C14.9105 10.6898 14.5748 10.354 14.1605 10.354L11.3555 10.354L11.3555 7.54894C11.3555 7.13473 11.0197 6.79894 10.6055 6.79894C10.1913 6.79894 9.85547 7.13473 9.85547 7.54894L9.85547 10.354L7.05039 10.354C6.63618 10.354 6.30039 10.6898 6.30039 11.104C6.30039 11.5182 6.63618 11.854 7.05039 11.854L9.85547 11.854V14.6591C9.85547 15.0733 10.1913 15.4091 10.6055 15.4091C11.0197 15.4091 11.3555 15.0733 11.3555 14.6591V11.854L14.1605 11.854Z" fill="currentColor" />
  </svg>
}

const GlobalStyles = css`
 html,
 body {
  
  }
`

const BrandPopupStyle = styled.div`
  .popup-wrap {
    position: relative;
    display: flex;
    min-width: 200px;
    max-width: 502px;
    max-height: 50vh;
    overflow-y: auto;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    background: #FFFFFF;
    color: #515151;

    .block-top {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      .logo {
        width: 42px;
        height: 42px;
        border-radius: 4px;
      }
      .top-right {
        width: calc(100% - 50px); /* 设置宽度 */
        flex: 1;
        .brand-name {
          line-height: 1;
          color: #121212;
          font-size: 16px;
          font-weight: 600;
          white-space: nowrap; /* 防止换行 */
          overflow: hidden; /* 溢出隐藏 */
          text-overflow: ellipsis; /* 显示省略号 */
        }
        .brand-slogan {
          margin-top: 6px;
          line-height: 1.2;
          color: #7D7D7D;
          font-size: 12px;
          font-weight: 400;
          white-space: nowrap; /* 防止换行 */
          overflow: hidden; /* 溢出隐藏 */
          text-overflow: ellipsis; /* 显示省略号 */
        }
      }
    }
    .brand-intro {
      margin-top: 20px;
      line-height: 1.5;
      color: #515151;
      font-size: 14px;
      font-weight: 400;
      word-break: break-all;
      /* max-height: 280px;
      overflow-y: scroll; */
    }
    .related-pictures {
      width: 100%;
      margin-top: 20px;
      .img {
        margin-right: 12px;
        .ant-image {
          img {
            width: 82px;
            height: 62px;
            object-fit: cover; /* 根据需要设置 cover, contain, fill, etc. */
          }
        }
      }
    }
  }
`;