/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import PopContainer from '../popContainer'
import styles from './index.module.scss'

interface MaterialSelectCheckMarksProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
  style?: React.CSSProperties
  className?: string
  label?: string | React.ReactNode
  options: Array<OptionType>
  value: any
  onSelect?: any
  greyBg?: boolean
  fieldRef?: any
  error?: any
  menuClassName?: any
}

interface OptionType {
  value: any
  label: string | boolean
}

const arrowIconStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '1em',
  height: '1em'
}

const ArrowIcon = (props) => {
  return (
    <div {...props} style={arrowIconStyle}>
      <svg xmlns='http://www.w3.org/2000/svg' width='11' height='7' viewBox='0 0 11 7' fill='none'>
        <path
          d='M9.46298 0.966675C10.3706 0.966675 10.8088 2.07844 10.1453 2.69773L6.18232 6.39651C5.79811 6.7551 5.20189 6.7551 4.81768 6.39651L0.854703 2.69773C0.191182 2.07844 0.629399 0.966674 1.53702 0.966674L9.46298 0.966675Z'
          fill='#86909C'
        />
      </svg>
    </div>
  )
}

const SingleSelect = ({
  id,
  label,
  options,
  className,
  onSelect,
  value,
  fieldRef,
  error,
  style,
  menuClassName = ''
}: MaterialSelectCheckMarksProps) => {
  const [open, setOpen] = useState(false)
  return (
    <FormControl fullWidth className={className} size='small'>
      <InputLabel id={`${id}-select-label`}>{label}</InputLabel>
      <Select
        {...fieldRef}
        variant='filled'
        error={error}
        labelId={`${id}-select-label`}
        id={id}
        open={open}
        onOpen={() => setOpen(true)}
        value={value}
        style={{ ...style, background: value?.length ? '#E7F1FB' : '#F0F0F0' }}
        label={label}
        input={<OutlinedInput label='Tag' />}
        IconComponent={(props) => <ArrowIcon {...props} />}
        renderValue={(value: any) => (
          <InputLabel
            id={`${id}-select-label`}
            style={{
              color: options.find((option) => option.value === value) ? '#136FD3' : undefined,
              overflow: 'hidden'
            }}
          >
            {options.find((option) => option.value === value)?.label ?? label}
          </InputLabel>
        )}
        MenuProps={{
          className: menuClassName
        }}
      >
        <PopContainer name={label} onClose={() => setOpen(false)}>
          {options &&
            options.map((option: any) => (
              <MenuItem
                selected={value === option.value}
                key={option.value}
                value={option.value}
                onClick={() => {
                  onSelect(option.value)
                  setOpen(false)
                }}
              >
                <ListItemText
                  primary={option.label}
                  className={styles.lineCamp}
                  style={{
                    color: value === option.value ? '#136FD3' : undefined
                  }}
                />
                {value === option.value ? (
                  <div>
                    <svg
                      width='19'
                      height='14'
                      viewBox='0 0 19 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M17.4545 2L7.14566 12L2 7'
                        stroke='#136FD3'
                        strokeWidth='2.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                ) : null}
              </MenuItem>
            ))}
        </PopContainer>
      </Select>
    </FormControl>
  )
}

export default SingleSelect
