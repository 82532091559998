'use client'
import { useEffect, useState, useMemo } from 'react'
import styled from '@emotion/styled'
import { postNotification, publishSharedData } from 'bossjob-remote/dist/clientStorage'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { Controller, useForm } from 'react-hook-form'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import Toast from 'app/components/Toast'
import Lottie from 'lottie-react'
import remoteAnimData from './remote-anim.json'

import Button from '@mui/material/Button'
import JobFunctionSelector from 'components/JobFunctionSelector'
import { getCountryKey } from 'helpers/country'
import { getCountryId } from 'helpers/country'
import MaterialBasicSelect from 'components/MaterialBasicSelect'
import { createUserPreferencesService } from 'store/services/users/addUserPreferences'
import { getSalaryOptions, getCountryList, getCurrencyList } from 'helpers/jobPayloadFormatter'
import { countryForCurrency } from 'helpers/country'
import { fetchUserOwnDetailService } from 'store/services/users/fetchUserOwnDetail'
import { fetchUserOwnDetailSuccess } from 'store/actions/users/fetchUserOwnDetail'
import Cascader from 'components/Cascader'
import { addParams } from 'helpers/utilities'
export default function RemotePrefModal({
  lang,
  open,
  onClose,
  onFinish,
  configs = null,
  arrangementDisabled = false,
  isRemote = true
}) {
  const country = getCountryKey()
  const config = useSharedData('CONFIG') ?? configs ?? {}
  const salaryRangeList = {
    1: config?.salary_ranges_lists || [],
    2: config?.daily_salary_range_lists || [],
    3: config?.hourly_salary_range_lists || []
  }

  const { handleSubmit, setValue, getValues, control, resetField, watch, formState } = useForm({
    defaultValues: {
      minSalary: '',
      maxSalary: '',
      location: '',
      currency: countryForCurrency(country),
      arrangement: isRemote ? '3' : '1',
      jobTypes: '',
      timezone: '',
      language: [],
      jobFunction: '',
      salaryTypeId: 1
    }
  })
  const watchCurrency = watch('currency')
  const salaryTypeId = watch('salaryTypeId')
  const [minSalary, setMinSalary] = useState(getValues().minSalary)
  const [maxSalary, setMaxSalary] = useState(null)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [locationData, setLocationData] = useState(null)
  const [maxSalaryOptions, setMaxSalaryOptions] = useState([])
  const [jobFunction, setJobFunction] = useState({
    id: '',
    value: '',
    is_featured: false,
    'seo-value': ''
  })
  const [arrangement, setArrangement] = useState('')
  const [timezone, setTimezone] = useState('')
  const [jobTypes, setJobTypes] = useState(null)
  const [language, setLanguage] = useState('')
  const [salaryTypeLists, setSalaryTypeLists] = useState([])
  const [currencyLists] = useMemo(() => {
    return [getCurrencyList(config), getCountryList(config)]
  }, [config])

  const minSalaryOptions = useMemo(
    () =>
      getSalaryOptions(
        salaryRangeList[salaryTypeId]?.find(
          (el) => el.currency_id === currencyLists.find((el) => el.key === getValues('currency')).id
        )
      ),
    [
      JSON.stringify(currencyLists),
      JSON.stringify(config?.salary_ranges_lists),
      watchCurrency,
      salaryTypeId
    ]
  )

  const formatData = (data, label, value) => {
    return data?.map((item) => ({
      label: item[label],
      value: item[value]
    }))
  }
  useEffect(() => {
    const data = config.salary_type_lists
    if (data?.length > 0) {
      const arr = [1, 5]

      if (arr.includes(jobTypes)) {
        setValue('salaryTypeId', 1)
        setSalaryTypeLists(data.filter((e) => e.id === 1 && e.id !== 4))
      } else {
        setSalaryTypeLists(data.filter(e => e.id !== 4))
      }
    }
  }, [jobTypes, config])
  const arrangementData = formatData(config.work_arrangement_lists, 'name', 'id')
  const languageData = formatData(config.job_languages, 'name', 'id')
  const jobTypesData = formatData(config.job_types, 'value', 'id')
  const getMaxSalaryOptions = (minSalary) => {
    const maxSalaryOptions = getSalaryOptions(
      salaryRangeList[salaryTypeId]?.find(
        (el) => el.currency_id === currencyLists.find((el) => el.key === getValues('currency')).id
      ),
      Number(minSalary),
      true
    )
    const maxSalaryOrg = maxSalaryOptions?.length > 0 ? maxSalaryOptions[0].value : null
    setValue('maxSalary', maxSalaryOrg)
    setMaxSalary(maxSalaryOrg)
    setMaxSalaryOptions(maxSalaryOptions)
  }
  const {
    desiredJobTitle,
    desiredJobType,
    desiredLocation,
    thisFieldIsRequired,
    desiredSalary,
    workArrangement,
    workLanguage  } = lang?.profile || {}

  const errorCode = lang.errorcode || {}

  const handleUpdateProfile = async (data) => {
    const { currency, location, maxSalary, minSalary, salaryTypeId } = data || {}
    let params = {
      job_title: jobFunction.value || '',
      function_job_title_id: jobFunction.id,
      salary_type_id: salaryTypeId,
      // function_job_title: jobFunction.value,
      currency_key: currency,
      // salary_range_from: Number(minSalary),
      // salary_range_to: Number(maxSalary),
      country_id: Number(arrangement) < 3 ? getCountryId() : undefined,
      //  location_id: Number(arrangement) < 3 ? location?.id : undefined,
      job_type_id: jobTypes,
      work_arrangement_id: arrangement || undefined,
      timezone_ids: Number(arrangement) === 3 ? timezone : undefined,
      language_ids: Number(arrangement) === 3 ? language : undefined
    }
    params = addParams(params, location)

    const getSalaryRangeKey = (payType) => {
      switch (payType) {
        case 1:
          return 'salary_range'
        case 2:
          return 'daily_salary_range'
        case 3:
          return 'hourly_salary_range'
        default:
          return ''
      }
    }
    const salaryRangeKey = getSalaryRangeKey(salaryTypeId)
    if (salaryRangeKey) {
      params[`${salaryRangeKey}_from`] = Number(minSalary)
      params[`${salaryRangeKey}_to`] = Number(maxSalary)
    }
    setLoading(true)

    createUserPreferencesService({ params })
      .then(async (res) => {
        if (res.data) {
          // jumPage()
          // onClose?.()
          const response = await fetchUserOwnDetailService()
          postNotification('DISPATCH', fetchUserOwnDetailSuccess(response?.data?.data))
          publishSharedData('ME', response?.data?.data)

          setTimeout(() => {
            onFinish?.(response?.data?.data)
          }, 1000)
        }
      })
      .catch(([, originError]) => {
        Toast.error(errorCode[originError?.data?.code])
        // Promise.resolve(err)
      })
      .finally(() => setLoading(false))
  }

  const resetFormValues = () => {
    Object.keys(formState.defaultValues).forEach((field) => {
      resetField(field as any)
    })
    setMinSalary('')
    setMaxSalary('')
    setIsDisabled(false)
    setLocationData(null)
    setJobFunction({ id: '', value: '', is_featured: false, 'seo-value': '' })
    setArrangement('')
    setTimezone('')
    setJobTypes('')
    setLanguage('')
  }
  useEffect(() => {
    !open && formState.isDirty && resetFormValues()
  }, [open, formState.isDirty])

  useEffect(() => {
    if (Number(arrangement) < 3) {
      if (jobFunction?.id && maxSalary && locationData && jobTypes) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    } else {
      if (jobFunction?.id && maxSalary
        //  && timezone 
        && language && jobTypes) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    }
  }, [
    jobFunction?.id,
    maxSalary,
    JSON.stringify(locationData),
    jobTypes,
    arrangement,
    timezone,
    language
  ])

  useEffect(() => {
    if (minSalary) {
      getMaxSalaryOptions(minSalary)
    }
  }, [minSalary])

  useEffect(() => {
    if (open) {
      if (isRemote) {
        setArrangement('3')
        setValue('arrangement', '3')
        setValue('currency', countryForCurrency('us'))
      } else {
        setArrangement('1')
        setValue('arrangement', '1')
        setValue('currency', countryForCurrency(country))
      }
    }
  }, [open])

  const initData = (data = []) => {
    return data?.map((item) => ({
      label: item.value,
      value: item.id
    }))
  }
  return (
    <DialogWrap onClose={onClose} open={open}>
      <div className='icon-close'>
        <IconButton aria-label='close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <div className='content'>
        <div className='image'>
          <Lottie
            animationData={remoteAnimData}
            loop={true}
            style={{ position: 'absolute', bottom: 0, width: '100%' }}
          />
          <div className='text'>
            <p
              className='blue'
              dangerouslySetInnerHTML={{
                __html: lang.manageProfile?.tab?.preference?.remoteModal?.workFromRemote
              }}
            />
            <p className='normal'>
              {
                lang.manageProfile?.tab?.preference?.remoteModal
                  ?.PleaseSetYourRemoteWorkPreferencesSoRecruitersUnderstandYourNeeds
              }
            </p>
          </div>
        </div>
        <div className='form'>
          <div className='field-content'>
            <p className='title'>
              <span>*</span>
              {desiredJobTitle}
            </p>
            <div className='field-row'>
              <Controller
                control={control}
                name='jobFunction'
                render={({ field }) => {
                  const { onChange } = field
                  return (
                    <JobFunctionSelector
                      // label={lang?.profile?.jobFunction}
                      // placeholder={lang?.profile?.jobFunction}
                      title={lang?.profile?.jobFunction}
                      lang={lang}
                      name='jobFunction'
                      variant='standard'
                      sx={{
                        '.MuiInput-input': {
                          padding: '4px 0 8px',
                          fontSize: '14px'
                        }
                      }}
                      isTouched
                      fullWidth
                      value={jobFunction}
                      onChange={(value) => {
                        onChange(value)
                        setJobFunction(value)
                      }}
                    />
                  )
                }}
              />
            </div>
            <p className='title'>
              <span>*</span>
              {desiredJobType}
            </p>
            <div className='field-row'>
              <Controller
                control={control}
                name='jobTypes'
                rules={{ validate: (value) => !!value || thisFieldIsRequired }}
                render={({ field, fieldState }) => {
                  const { value, onChange } = field
                  return (
                    <MaterialBasicSelect
                      // required
                      options={jobTypesData}
                      fieldRef={{
                        variant: 'standard'
                      }}
                      {...fieldState}
                      {...field}
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        setJobTypes(e.target.value)
                      }}
                      inputFontSize='14px'
                      ref={undefined}
                    />
                  )
                }}
              />
            </div>
            <p className='title'>
              <span>*</span>
              {workArrangement}
            </p>
            <div className='field-row'>
              <Controller
                control={control}
                name='arrangement'
                rules={{ validate: (value) => !!value || thisFieldIsRequired }}
                render={({ field, fieldState }) => {
                  const { value, onChange } = field
                  return (
                    <MaterialBasicSelect
                      // label="james"
                      // required
                      options={arrangementData}
                      fieldRef={{
                        variant: 'standard'
                      }}
                      {...fieldState}
                      {...field}
                      disabled={arrangementDisabled}
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        setArrangement(e.target.value)
                        setMaxSalary('')
                        setMinSalary('')
                        setValue('maxSalary', '')
                        setValue('minSalary', '')
                        if (e.target.value === 3) {
                          setValue('currency', countryForCurrency('us'))
                        } else {
                          setValue('currency', countryForCurrency(country))
                        }
                      }}
                      inputFontSize='14px'
                      ref={undefined}
                    />
                  )
                }}
              />
            </div>
            {arrangement && Number(arrangement) === 3 ? (
              <>
                {/* <p className='title'>
                  <span>*</span>
                  {workTimezone}
                </p>
                <div className='field-row'>
                  <Controller
                    control={control}
                    name='timezone'
                    rules={{ validate: (value) => !!value || thisFieldIsRequired }}
                    render={({ field, fieldState }) => {
                      const { value, onChange } = field
                      return (
                        <MaterialBasicSelect
                          // label={lang?.profile?.minSalary}
                          options={timezoneData}
                          fieldRef={{
                            variant: 'standard'
                          }}
                          {...fieldState}
                          {...field}
                          value={value}
                          onChange={(e) => {
                            onChange(e)
                            setTimezone(e.target.value)
                          }}
                          inputFontSize='14px'
                          ref={undefined}
                        />
                      )
                    }}
                  />
                </div> */}

                <p className='title'>
                  <span>*</span>
                  {workLanguage}
                </p>
                <div className='field-row'>
                  <Controller
                    control={control}
                    name='language'
                    rules={{ validate: (value) => !!value || thisFieldIsRequired }}
                    render={({ field, fieldState }) => {
                      const { value, onChange } = field
                      return (
                        <MaterialBasicSelect
                          // label={lang?.profile?.minSalary}
                          multiple
                          checkRequired={true}
                          options={languageData}
                          fieldRef={{
                            variant: 'standard'
                          }}
                          {...fieldState}
                          {...field}
                          value={value}
                          onChange={(e) => {
                            onChange(e)
                            setLanguage(e.target.value.join(','))
                          }}
                          inputFontSize='14px'
                          ref={undefined}
                          multipleValue={value}
                          renderValue={(selected) =>
                            selected
                              .map((id) => ({
                                id,
                                label: languageData.find((item) => item.value === id)?.label
                              }))
                              .map((item) => item.label)
                              .join(', ')
                          }
                        />
                      )
                    }}
                  />
                </div>
              </>
            ) : null}
            {arrangement && Number(arrangement) < 3 ? (
              <>
                <p className='title'>
                  <span>*</span>
                  {desiredLocation}
                </p>
                <div className='field-row'>
                  <Controller
                    control={control}
                    name={'location'}
                    // rules={{ required: thisFieldIsRequired }}
                    render={({ field, fieldState: { error } }) => {
                      const { onChange } = field
                      return (
                        <Cascader
                          error={error}
                          required
                          variant='borderLess'
                          // label={currentLocation}
                          // preference={preference}
                          onChange={(location) => {
                            setLocationData(location)
                            onChange(location)
                          }}
                        />

                        //   <MaterialLocationField
                        //     className='location-select'
                        //     // className={styles.stepFullwidth}
                        //     // label={currentLocation}
                        //     required
                        //     disableClearable={true}
                        //     fieldRef={{
                        //       variant: 'standard'
                        //       // placeholder: desiredLocation
                        //     }}
                        //     {...fieldState}
                        //     {...field}
                        //     onChange={(_, location) => {
                        //       // setIsShowCountry(location?.key === 'overseas')
                        //       setLocationData(location)
                        //       onChange(location)
                        //     }}
                        //   />
                      )
                    }}
                  />
                </div>
              </>
            ) : null}
            <p className='title'>
              <span>*</span>
              {desiredSalary}
            </p>
            <div className='field-row field-row-multiple'>
              <div className='field'>
                <Controller
                  control={control}
                  name='currency'
                  rules={{ required: thisFieldIsRequired }}
                  render={({ field, fieldState }) => {
                    const { value, onChange } = field
                    return (
                      <MaterialBasicSelect
                        // label={desiredSalaryCurrency}
                        options={currencyLists}
                        disabled={Number(arrangement) < 3}
                        variant='standard'
                        {...fieldState}
                        {...field}
                        ref={undefined}
                        value={value}
                        onChange={(e) => {
                          onChange(e)
                          setMaxSalary('')
                          setMinSalary('')
                          setValue('maxSalary', '')
                          setValue('minSalary', '')
                        }}
                      />
                    )
                  }}
                />
              </div>
              <div className='field'>
                <Controller
                  control={control}
                  name={'salaryTypeId'}
                  rules={{ validate: (value) => !!value || thisFieldIsRequired }}
                  render={({ field, fieldState }) => {
                    const { value, onChange } = field
                    return (
                      <MaterialBasicSelect
                        // label={lang?.salaryType?.payType}
                        options={initData(salaryTypeLists)}
                        fieldRef={{
                          variant: 'standard'
                        }}
                        {...fieldState}
                        {...field}
                        value={value}
                        onChange={(e) => {
                          onChange(e)
                          setValue('minSalary', '')
                          setValue('maxSalary', '')
                          setMinSalary('')
                          setMaxSalary('')
                        }}
                      />
                    )
                  }}
                />
              </div>
              <div className='field'>
                <Controller
                  control={control}
                  name={'minSalary'}
                  rules={{ validate: (value) => !!value || thisFieldIsRequired }}
                  render={({ field, fieldState }) => {
                    const { value, onChange } = field
                    return (
                      <MaterialBasicSelect
                        // label={lang?.profile?.minSalary}
                        options={minSalaryOptions}
                        // required
                        fieldRef={{
                          variant: 'standard'
                        }}
                        {...fieldState}
                        {...field}
                        value={value}
                        onChange={(e) => {
                          setMinSalary(e.target.value)
                          onChange(e)
                        }}
                        ref={undefined}
                      />
                    )
                  }}
                />
              </div>
              <div className='field'>
                <Controller
                  control={control}
                  name={'maxSalary'}
                  rules={{ validate: (value) => !!value || thisFieldIsRequired }}
                  render={({ field, fieldState }) => {
                    const { value, onChange } = field
                    return (
                      <MaterialBasicSelect
                        // label={lang?.profile?.maxSalary}
                        rules={{ required: thisFieldIsRequired }}
                        // required
                        options={maxSalaryOptions}
                        variant='standard'
                        {...fieldState}
                        {...field}
                        onChange={(e) => {
                          setMaxSalary(e.target.value)
                          onChange(e)
                        }}
                        value={value}
                        disabled={!minSalary}
                        ref={undefined}
                      />
                    )
                  }}
                />
              </div>
            </div>
          </div>
          <div className='button-content'>
            <Button variant='outlined' onClick={onClose}>
              Skip
            </Button>
            <LoadingButton
              variant='contained'
              disabled={isDisabled}
              loading={loading}
              onClick={handleSubmit(handleUpdateProfile)}
            >
              <span>Save</span>
            </LoadingButton>
          </div>
        </div>
      </div>
    </DialogWrap>
  )
}
const DialogWrap = styled(Dialog)`
  & .MuiPaper-root {
    width: 880px;
    max-width: 880px;
    box-shadow: none;
    border-radius: 8px;
    // height: 700px;
    box-sizing: border-box;
    .icon-close {
      & .MuiButtonBase-root {
        position: absolute;
        right: 8px;
        top: 8px;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      .image {
        position: relative;
        -webkit-transform: translate3d(0, 0, 0);
        width: 400px;
        min-height: 702px;
        background: #fffdfb;

        .text {
          position: absolute;
          z-index: 10;
          top: 0;
          width: 100%;
          text-align: center;
          padding-top: 50px;
          overflow: hidden;
          line-height: 1;
          p {
            margin: 0;
            padding: 0;
          }
          .normal {
            padding: 10px 40px 0;
            line-height: 1.4;
          }
          .blue {
            font-size: 56px;
            font-weight: 700;
            color: #2378e5;
          }
        }
        img {
          max-width: 100%;
          width: 100%;
          height: auto;
          vertical-align: middle;
        }
      }
      .form {
        padding: 55px 20px 25px;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .field-content {
          flex: 1;
          .field-row {
            margin-bottom: 25px;

            & .MuiFormControl-root {
              width: 100%;
            }
            & .MuiInputBase-root {
              &:after {
                border-width: 1px;
              }
            }
            & .MuiInput-root {
              margin-top: 5px;
              // padding: 3px 5px 2px;
              // box-sizing: border-box;
              border-bottom: 1px solid #e4e4e4;
              &:before {
                border: none;
              }
            }
            .location-select {
              & .MuiButtonBase-root {
                right: 0;
                top: 0;
              }
              & .MuiInput-root {
                font-size: 14px;
              }
            }
          }
          .field-row-multiple {
            display: flex;
            justify-content: space-between;
            .field {
              width: 130px;
            }
          }

          .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 23px;
            padding-bottom: 6px;
            color: #121212;

            span {
              color: red;
              padding-right: 5px;
              position: relative;
              top: 4px;
            }
          }
        }
        .button-content {
          display: flex;
          justify-content: space-between;
          height: 60px;

          & .MuiButton-root {
            width: 210px;
            border-radius: 8px;
            position: static;
            height: 100%;
            text-transform: capitalize;
            font-weight: normal;
            font-size: 18px;
          }
          & .MuiLoadingButton-loading {
            position: relative;
            top: 0;
          }
          & .MuiButton-outlined {
            border: 1px solid #e4e4e4;
            color: #515151;
            transition: none;
            &:hover {
              background: transparent;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 540px) {
    & .MuiPaper-root {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      margin: 0;
      height: auto;
      border-radius: 0;
      .content {
        .image {
          display: none;
        }
        .form {
          width: 100%;
          .field-content {
            .field-row-multiple {
              .field {
                width: 100px;
              }
            }
          }
          .button-content {
            height: 50px;
            & .MuiButton-root {
              width: 165px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
`
