'use client'

import React from 'react'
import styles from '../../Footer.module.scss'
import Link from 'components/Link'
import Text from 'components/Text'
import linkToHunt from 'helpers/linkToHunt'
import { jpUrl } from 'helpers/constants'
const RecruiterLink = (props: any) => {
  const { blogAddressUrl, langKey, data, countryKey } = props
  const { joinBossjob, employerHelplines, AITalentSolution, becomeAPartner, postingGuidance } =
    data?.foot || {}

  // const countryKey = getCountryKey()
  // const huntLangKey = getLanguageCode(langKey)
  // const joinBossJobUrl = `${process.env.BOSSHUNT_URL}/get-started-company?country=${countryKey}&lang=${huntLangKey}`

  const joinBossJobUrl = linkToHunt('manage-jobs/all/1', langKey, countryKey)
  const isJapan = countryKey === 'jp'

  return (
    <ul className={styles.footerDesktopLinkList}>
      <li>
        <Link className={styles.footerLink} to={joinBossJobUrl} external title={joinBossjob}>
          <Text textStyle='sm'>{joinBossjob}</Text>
        </Link>
      </li>
      <li>
        <Link
          className={styles.footerLink}
          to={blogAddressUrl?.EmployerHelplines}
          external
          title={employerHelplines}
        >
          <Text textStyle='sm'>{employerHelplines}</Text>
        </Link>
      </li>
      <li>
        <Link
          className={styles.footerLink}
          to={
            isJapan
              ? jpUrl
              : `${process.env.BOSSHUNT_URL}/boss?country=${countryKey}&lang=${langKey}`
          }
          external
          title={AITalentSolution}
        >
          <Text textStyle='sm'>{AITalentSolution}</Text>
        </Link>
      </li>
      <li>
        <Link
          className={styles.footerLink}
          to={blogAddressUrl?.BecomeaPartner}
          external
          title={becomeAPartner}
        >
          <Text textStyle='sm'>{becomeAPartner}</Text>
        </Link>
      </li>
      {countryKey === 'jp' ? (
        <li>
          <Link
            className={styles.footerLink}
            to={
              'https://bossjob.crisp.help/ja/article/5rgc5lq644ks44kk44oa44oz44k5-xfaw82/?bust=1718100300947'
            }
            external
            title={postingGuidance}
          >
            <Text textStyle='sm'>{postingGuidance}</Text>
          </Link>
        </li>
      ) : null}
    </ul>
  )
}

export default RecruiterLink
