'use client'
import React, { useContext, useEffect } from 'react'
import { languageContext } from 'app/components/providers/languageProvider'
import { getSalaryValues } from 'helpers/salary'

const ShowSalary = ({ jobs, size = 14, dictionary = {}, showTip = true }: any) => {
  const { salaryType } = useContext(languageContext) as any
  // const { Monthly = 'Monthly', Daily = 'Daily', Hourly = 'Hourly' } = salaryType || {}
  const {
    salary_type_id,
    salary_type = {},
    salary_range_value,
    local_salary_range_value,
    local_daily_salary_range_value,
    local_hourly_salary_range_value,
    local_annually_salary_range_value,
    salary_range,
    daily_salary_range,
    hourly_salary_range,
    annually_salary_range_value,
    work_arrangement_id
  } = jobs || {}

  const jobSalary = {
    salary_range_value,
    local_salary_range_value,
    local_daily_salary_range_value,
    local_hourly_salary_range_value,
    local_annually_salary_range_value,
    salary_range,
    daily_salary_range,
    hourly_salary_range,
    annually_salary_range_value
  }

  // console.log({ jobs })
  // const salaryValues = {
  //   1: { value: local_salary_range_value || salary_range_value || salary_range, label: Monthly },
  //   2: {
  //     value: local_daily_salary_range_value || salary_range_value || daily_salary_range,
  //     label: Daily
  //   },
  //   3: {
  //     value: local_hourly_salary_range_value || salary_range_value || hourly_salary_range,
  //     label: Hourly
  //   }
  // }
  // const { value, label } = salaryValues[salary_type_id || salary_type?.id || 1] || {}

  let { salaryValue, salaryTypeName } = getSalaryValues(
    salaryType,
    salary_type_id || salary_type?.id || 1,
    jobSalary,
    work_arrangement_id
  )

  if (!jobs?.is_un_paid) {
    function containsNumber(str) {
      return /\d/.test(str)
    }
    let hasNumber = containsNumber(`${salaryValue}`)

    return (
      <>
        {salaryValue || dictionary?.salaryType?.Unpaid}
        {hasNumber && showTip && (
          <span
            style={{
              color: '#2378e5',
              fontSize: size,
              letterSpacing: '0.12px',
              fontWeight: '400'
            }}
          >
            {/* <i style={{ padding: '0 6px', fontSize: '14px' }}>|</i> */}[
            {dictionary?.salaryType?.[salaryTypeName] || salaryTypeName}]
          </span>
        )}
      </>
    )
  }
  return <>{dictionary?.salaryType?.Unpaid}</>
}

export default ShowSalary
