import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './drawer.module.scss';
import classNames from 'classnames';

export const Drawer = ({ children, open, onClose }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const isOpen = open
  // const toggleDrawer = () => {
  //   setIsOpen(!isOpen);
  // };

  const closeDrawer = () => {
    onClose(false);
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        closeDrawer();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return ReactDOM.createPortal(
    <div>
      {isOpen && <div className={styles.overlay} onClick={onClose}></div>}
      <div className={
        classNames(styles.drawer, isOpen ? styles.open : '')
      }>
        {children}
      </div>
    </div>,
    document.body
  );
};