import * as React from "react"
const InfoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#FF9500"
      d="M10.779 18.833a8.333 8.333 0 1 0 0-16.667 8.333 8.333 0 0 0 0 16.667Zm.834-12.946v6.666H9.946V5.887h1.667Zm-1.668 9.612v-1.666h1.667v1.666H9.945Z"
    />
  </svg>
)
export default InfoIcon
