/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useRef } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import PopContainer from '../../popContainer'
import styles from '../index.module.scss'

interface MaterialSelectCheckMarksProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
  style?: React.CSSProperties
  className?: string
  label?: string | React.ReactNode
  options: Array<OptionType>
  value: any
  onSelect?: any
  greyBg?: boolean
  fieldRef?: any
  error?: any
  menuClassName?: any
}

interface OptionType {
  value: any
  label: string | boolean
}

const arrowIconStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '1em',
  height: '1em'
}

const ArrowIcon = (props) => {
  return (
    <div {...props} style={arrowIconStyle}>
      <svg xmlns='http://www.w3.org/2000/svg' width='11' height='7' viewBox='0 0 11 7' fill='none'>
        <path
          d='M9.46298 0.966675C10.3706 0.966675 10.8088 2.07844 10.1453 2.69773L6.18232 6.39651C5.79811 6.7551 5.20189 6.7551 4.81768 6.39651L0.854703 2.69773C0.191182 2.07844 0.629399 0.966674 1.53702 0.966674L9.46298 0.966675Z'
          fill='#86909C'
        />
      </svg>
    </div>
  )
}

const ArrowFoldIcon = ({ style }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' fill='none' style={{ ...style }}>
    <path
      d='M12.459 10.1395L11.5397 11.0587L7.99936 7.51832L4.45898 11.0587L3.53975 10.1395L7.99936 5.67984L12.459 10.1395Z'
      fill='black'
      fillOpacity='0.6'
    />
  </svg>
)

const MultipleSelect = ({
  id,
  label,
  options,
  className,
  onSelect,
  style,
  value,
  fieldRef,
  error,
  menuClassName = ''
}: MaterialSelectCheckMarksProps) => {
  const valueRef = useRef(value)
  const [selectedOptions, setSelectedOptions] = useState<any>(value || [])
  const [newOptions, setNewOptions] = useState([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setSelectedOptions(value)
  }, [value])

  useEffect(() => {
    if (options?.length > 0) {
      setNewOptions(options)
    }
  }, [options])

  useEffect(() => {
    if (open && value.length) {
      setNewOptions((state) =>
        state.map(({ ...item }) => {
          return {
            ...item,
            sub_filter: item.sub_filter.map(({ ...item }) => {
              item.selected = value.includes(item.value)
              return item
            })
          }
        })
      )
    }
  }, [open, JSON.stringify(value)])

  const handleChange = (value: string) => {
    const formattedValue = value.toLowerCase()
    if (selectedOptions.includes(formattedValue)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== formattedValue))
    } else {
      setSelectedOptions([...selectedOptions, formattedValue])
    }
    setNewOptions((state) =>
      state.map(({ ...item }) => {
        return {
          ...item,
          sub_filter: item.sub_filter.map(({ ...item }) => {
            if (value === item.value) {
              item.selected = !selectedOptions.includes(formattedValue)
            }
            return item
          })
        }
      })
    )
  }

  return (
    <FormControl fullWidth className={className} size='small'>
      <InputLabel id={`${id}-select-label`}>{label}</InputLabel>
      <Select
        {...fieldRef}
        variant='filled'
        error={error}
        labelId={`${id}-select-label`}
        id={id}
        multiple
        open={open}
        autoFocus={false}
        onOpen={() => setOpen(true)}
        style={{ ...style, background: selectedOptions?.length ? '#E7F1FB' : '#F0F0F0' }}
        value={selectedOptions.length ? selectedOptions : [label]}
        label={label}
        IconComponent={(props) => <ArrowIcon {...props} />}
        input={<OutlinedInput label='Tag' />}
        renderValue={(selected: any) => {
          if (selectedOptions?.length) {
            return (
              <div
                style={{
                  position: 'relative',
                  top: 2
                }}
              >
                {`${label} (${selected.length})`}
              </div>
            )
          } else {
            return (
              <div
                style={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  position: 'relative',
                  top: 2
                }}
              >
                {label}
              </div>
            )
          }
        }}
        MenuProps={{
          className: menuClassName
        }}
      >
        <PopContainer
          name={label}
          multiple
          onClose={() => {
            setSelectedOptions(valueRef.current)
            setOpen(false)
          }}
          style={{
            left: '0'
          }}
          onSave={() => {
            onSelect(selectedOptions)
            valueRef.current = selectedOptions
            setOpen(false)
          }}
          onReset={() => {
            setSelectedOptions([])
            setNewOptions((state) =>
              state.map(({ ...item }) => {
                return {
                  ...item,
                  sub_filter: item.sub_filter.map(({ ...item }) => {
                    item.selected = false
                    return item
                  })
                }
              })
            )
          }}
        >
          <div className={styles.industryContainer}>
            {newOptions?.length > 0 &&
              newOptions.map((item: any, idx) => {
                return (
                  <React.Fragment key={item.id}>
                    <div
                      onClick={() => setNewOptions(state => state.map(({ ...item }: any, i) => {
                        if (i === idx) {
                          item.expand = !item.expand
                        }
                        else {
                          item.expand = false
                        }
                        return item

                      }))}

                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 16px'
                      }}
                    >
                      <h3
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          margin: '14px 0'
                        }}
                      >
                        {item.value}
                      </h3>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        {Boolean(item.sub_filter.filter(item => item.selected).length) &&
                          <span className={styles.count}>{item.sub_filter.filter(item => item.selected).length}</span>}
                        <ArrowFoldIcon style={{
                          transform: `rotate(${!item.expand ? 180 : 0}deg)`
                        }} />
                      </div>

                    </div>
                    {item.sub_filter.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        onClick={() => handleChange(option.value)}
                        style={{
                          display: item.expand ? '' : 'none',
                          wordBreak: 'break-word',
                          wordWrap: 'break-word',
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        <ListItemText
                          primary={option.label}
                          style={{
                            paddingLeft: '4px',
                            color:
                              selectedOptions?.indexOf?.(option.value?.toLowerCase()) > -1
                                ? '#136FD3'
                                : undefined
                          }}
                        />
                        {selectedOptions?.indexOf?.(option.value?.toLowerCase()) > -1 ? (
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
                              <path fillRule="evenodd" clipRule="evenodd" d="M16.1788 6.75407L8.40169 14.8552C8.24454 15.0189 8.02744 15.1115 7.80053 15.1115C7.57362 15.1115 7.35652 15.0189 7.19938 14.8552L3.82227 11.3374L5.02458 10.1832L7.80053 13.0748L14.9765 5.59985L16.1788 6.75407Z" fill="#2378E5" />
                            </svg>
                          </div>
                        ) : null}
                      </MenuItem>
                    ))}
                  </React.Fragment>
                )

              })}
          </div>

        </PopContainer>
      </Select>
    </FormControl>
  )
}

export default MultipleSelect
