'use client'

import React, { useEffect, useMemo, useState } from 'react'
import { MoneyConvert, } from './util'
import { Button, Dropdown, Menu, Modal, } from 'antd'
import { getMoneyRateList } from './service'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import styles from './index.module.scss'
import { NumberInput } from './NumberInput'

const fetchMoneyList = () => getMoneyRateList().then(res => {
  const data = res?.data?.data || [];
  return data
})

const sortCurrency = (newData) => {
  const supportList = ['usd', 'eur', 'gbp', 'jpy', 'php', 'sgd', 'cny']
  newData.forEach(element => {
    const index = supportList.indexOf(`${element.code}`.toLocaleLowerCase());
    if (index !== -1) {
      element.index = supportList.length - index
    } else {
      element.index = -1
    }
  });

  newData.sort((v1, v2) => v2.index - v1.index).forEach(ele => {
    delete ele.index
  });

  return newData
}

const CloseIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M6.5 6.5L17.5 17.5" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M6.5 17.5L17.5 6.5" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>

const DownIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M9.69161 12.0547L6.46684 8.50749C6.22352 8.23984 6.41343 7.81055 6.77515 7.81055H13.2247C13.5864 7.81055 13.7763 8.23984 13.533 8.50749L10.3082 12.0547C10.1429 12.2366 9.85693 12.2366 9.69161 12.0547Z" fill="#7D7D7D" />
</svg>

const SwitchIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path d="M10.875 15.75L10.875 2.25" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M15.375 11.25L10.875 15.75" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M7.125 2.5498L7.125 16.0498" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M7.125 2.5498L2.625 7.04982" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>

const Switcher = ({ onClick }: { onClick: React.MouseEventHandler<HTMLElement> }) => {
  return <div style={{ position: 'relative', height: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <Button style={{ border: 'none' }} onClick={onClick} shape="circle" icon={<SwitchIcon />} />
  </div>
}

const FullNameLabel = ({ label = 'From', currency }) => {
  const { fullName } = currency || {}
  return <div className={styles.row} style={{ fontSize: 14 }}>
    {label}
    <span style={{ fontSize: 12, color: '#515151', marginLeft: 8 }}>{fullName}</span>
  </div>
}

const ConvertField = ({ options, rightSlot, currency, onItemClick }) => {

  return (
    <div className={styles.convertFieldRow}>
      <Dropdown
        getPopupContainer={(triggerNode) => triggerNode}
        dropdownRender={() => {
          return (
            <div style={{ overflow: 'hidden', maxHeight: 300 }}>
              <div style={{ maxHeight: 300, overflow: 'auto' }}>
                <Menu>
                  {options.map((v) => {
                    return (
                      <Menu.Item
                        key={v.id}
                        onClick={() => {
                          onItemClick?.(v)
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                          <img
                            width={24}
                            height={16}
                            src={v.flag}
                            style={{ objectFit: 'cover', border: '1px solid #F6F6F6' }}
                          />
                          {v.code}
                        </div>
                      </Menu.Item>
                    )
                  })}
                </Menu>
              </div>
            </div>
          )
        }}
        placement='bottom'
      >
        <div
          className={styles.row}
          style={{
            color: '#121212',
            fontSize: 20,
            fontWeight: 500,
            lineHeight: '24px',
            gap: 6,
            cursor: 'pointer'
          }}
        >
          <img src={currency?.flag} width={24} height={16} style={{ objectFit: 'cover' }} />
          {currency?.code}
          <DownIcon />
        </div>
      </Dropdown>
      {rightSlot}
    </div>
  )
}

const TimeFooter = ({ time, prefix }) => {
  return time && <div className={styles.timeFooter}>
    {prefix || 'Exchange rate base time:'} {new Date(time).toLocaleString()}
  </div>
}

export const MoneyExchangeCard = ({ open, onClose }) => {
  const [rate, setRate] = useState([]);
  const langDic = useSharedData('DICTIONARY') || {}

  const [selectedRate, setSelectedRate] = useState({
    from: null,
    to: null
  });
  const [convertValue, setConvertValue] = useState<number>(null)

  const memoList = rate
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const isModalOpen = open

  useEffect(() => {
    fetchMoneyList().then(data => {
      let newData = data.map(v => {
        const flag = `${process.env.S3_BUCKET_URL}/currency/flags/${`${v.currency_code}`.toLocaleUpperCase()}.png`

        return {
          id: v.currency_id,
          symbol: v.display_symbol,
          rate: v.to_usd_exchange,
          fullName: v.currency_name,
          code: v.currency_code,
          updateTime: v.exchange_update_time,
          flag
        }
      })
      newData = sortCurrency(newData)

      setSelectedRate({ from: newData[0], to: newData[1] })
      setRate(newData)
    })
  }, [])

  const convertedValue = useMemo(() => {
    const { from, to } = selectedRate
    if (!from || !to || !memoList.length) {
      return {
        value: convertValue,
      }
    }
    const value = MoneyConvert({ options: memoList })(convertValue || 0, from.id, to.id)

    return value
  }, [selectedRate, convertValue])

  const handleOk = () => {
    onClose(false);
  };

  const handleCancel = () => {
    onClose(false);
  };

  return <>
    <Modal
      width={500}
      className={styles.moneyExchangeCard}
      title={<></>}
      closeIcon={<></>}
      footer={null}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div style={{ display: 'flex', gap: 32, flexDirection: 'column' }}>
        <div className={styles.header}>
          <div className={styles.headerText}>
            {/* Currency calculator */}
            {langDic?.currencyCalculator?.title}
          </div>
          <span className={styles.closeIconWrapper} onClick={handleCancel} >
            <CloseIcon />
          </span>
        </div>

        <div>
          <div className={styles.convertFieldWrapper}>
            <FullNameLabel
              label={langDic?.comm?.from}
              currency={selectedRate.from}
            />
            <ConvertField
              currency={selectedRate.from}
              options={rate}
              rightSlot={
                <NumberInput
                  placeholder='0.00'
                  className={styles.exchangeNumberInput}
                  style={{ textAlign: 'right' }}
                  onChange={(value) => {
                    setConvertValue(value)
                  }}
                />
              }
              onItemClick={item => {
                setSelectedRate(pre => {
                  return { ...pre, from: item }
                })
              }}
            />
          </div>

          <Switcher
            onClick={() => {
              setSelectedRate(pre => {
                const { from, to } = pre
                return { from: to, to: from }
              })
            }}
          />

          <div className={styles.convertFieldWrapper}>
            <FullNameLabel
              label={langDic?.comm?.to}
              currency={selectedRate.to}
            />
            <ConvertField
              currency={selectedRate.to}
              options={rate}
              onItemClick={item => {
                setSelectedRate(pre => {
                  return { ...pre, to: item }
                })
              }}
              rightSlot={<div className={styles.money}>
                {!convertValue ? <span style={{ color: '#B8B8B8' }}>0.000</span> : convertedValue.value}
              </div>} />
          </div>
        </div>

        <TimeFooter time={rate[0]?.updateTime} prefix={langDic?.currencyCalculator?.time} />
      </div>
    </Modal>
  </>
}
