'use client'
import React, { useEffect } from 'react'
import { jobCountSourceTracker, reportTrackerEvent } from './util'
import { getDeviceUuid } from 'helpers/guest'

interface IJobCountTrackerOptions {
  job_ids: string
  source: string
}

interface IJobCountTracker {
  data: IJobCountTrackerOptions
}

const jobSources = Object.values(jobCountSourceTracker)

const isValidSource = (source: any) => {
  return jobSources.includes(source)
}

export async function reportTrackerJobCounts(data: IJobCountTrackerOptions) {
  try {
    if (typeof window == 'undefined') return
    if (!isValidSource(data?.source)) return
    const device_udid = await getDeviceUuid()
    const params = { ...data, device_udid }
    if (params?.job_ids) reportTrackerEvent('job', '/show/job-counts', 'POST', params)
  } catch (error) {
    console.error(error)
  }
}

const JobCountTracker = (props: IJobCountTracker) => {
  const { data } = props
  useEffect(() => {
    if (!data) return
    reportTrackerJobCounts(data)
  }, [data])
  return <></>
}

export default JobCountTracker
